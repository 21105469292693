import { useState, useEffect } from "react";

import {
  SupabaseComment,
  fetchCommentByThreadId,
  addComment,
  removeComment,
} from "../api/comments";

export const useComments = (threadId: string, bookIdFilter?: string | null) => {
  const [comments, setComments] = useState<SupabaseComment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredComments, setFilteredComments] = useState<SupabaseComment[]>(
    []
  );

  useEffect(() => {
    setIsLoading(true);
    fetchCommentByThreadId(threadId)
      .then((fetchedComments) => {
        setComments(fetchedComments);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [threadId]);
  // Filter comments based on bookId when either comments or bookIdFilter change
  useEffect(() => {
    if (bookIdFilter) {
      setFilteredComments(
        comments.filter((comment) => comment.bookId === bookIdFilter)
      );
    } else {
      setFilteredComments(comments);
    }
  }, [comments, bookIdFilter]);

  const createComment = async (
    userId: string,
    content: string,
    bookId: string | null
  ) => {
    await addComment(userId, content, threadId, bookId);
    const updatedComments = await fetchCommentByThreadId(threadId);
    setComments(updatedComments);
  };

  const deleteComment = async (commentId: string) => {
    console.log("Delete comment:", commentId);
    await removeComment(commentId);

    const updatedComments = await fetchCommentByThreadId(threadId);
    setComments(updatedComments);
  };

  return {
    comments: filteredComments,
    isLoading,
    createComment,
    deleteComment,
  };
};
