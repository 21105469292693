// Annotation.js

import { AnimatePresence } from "framer-motion";

import React, { useEffect, useState } from "react";
import SegmentedImage from "./SegmentedImage";
import BookList from "./BookList";
import { useQuery } from "react-query";
import { useSession } from "../context/hooks/useSession";
import { fetchImage, fetchImages, ImageData } from "../api/images";
import { useNavigate, useParams } from "react-router-dom";
import { S3_BUCKET_PREFIX } from "../constants";
import { useMasks, Mask } from "../hooks/useMasks";
import { SearchBar } from "./SearchBar";
import PolygonMaskedImage, { Point } from "./PolygonMaskedImage";
import { useModel } from "../hooks/useModel";
import Selection from "./Selection";
import AnnotateMask from "./Annotation";
import { fetchBooksByImageId, fetchBooksByMaskId } from "../api/books";
import { Spinner } from "@chakra-ui/react";
import { useProcessedImageData } from "../hooks/useImage";

export const LoadingBooksSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={610} height={100} fill="none">
    <path
      fill="#000"
      d="M50.75 70.833a27.255 27.255 0 0 1 10.417-15.625l-3-4-11.459 14.75-8.166-9.833-11.459 14.708H50.75Zm-29.917-50v58.334h29.292c.25 2.916.875 5.75 1.958 8.333h-31.25a8.192 8.192 0 0 1-5.875-2.458 8.192 8.192 0 0 1-2.458-5.875V20.833c0-4.583 3.75-8.333 8.333-8.333h58.334c2.208 0 4.333.875 5.875 2.458a8.192 8.192 0 0 1 2.458 5.875v31.25c-2.583-1.083-5.417-1.708-8.333-1.958V20.833H20.833Zm53.125 70.834L62.5 79.167l4.833-4.834 6.625 6.625L88.917 66l4.833 5.875-19.792 19.792ZM495.061 51.06a1.502 1.502 0 0 0 0-2.12l-9.546-9.547a1.5 1.5 0 0 0-2.122 2.122L491.879 50l-8.486 8.485a1.501 1.501 0 0 0 2.122 2.122l9.546-9.546ZM116 51.5h378v-3H116v3ZM610 100H510v-4.546h100V100ZM516.113 0h25.197v100h-25.197V0Zm20.83 4.545H520.48v90.91h16.463V4.544Zm.044 21.5h21.048V100h-21.048V26.045Zm16.681 4.546h-12.314v64.863h12.314V30.591Zm24.978-26.273h25.197V100h-25.197V4.318Zm20.83 4.545h-16.463v86.591h16.463V8.864ZM553.668 13h29.389v87h-29.389V13Zm25.022 4.546h-20.655v77.908h20.655V17.546Zm-22.839 60.772H539.17v-4.546h16.682l-.001 4.546Zm16.638 4.318h-8.34v-4.545h8.34v4.545Zm-33.319 0h-20.873v-4.545h20.873v4.545Zm0-60.727h-20.873v-4.545h20.873v4.545Z"
    />
  </svg>
);

function ProcessShelf() {
  // const { imageId } = useParams();
  const imageId = "98bc8b43-e7e9-45ed-8025-09c3e00319c6";
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);
  const [isAnnotating, setIsAnnotating] = useState(false);

  const {
    masks,
    removeMask,
    persistMask,
    filterMasks,
    rotateMaskImage,
    searchResultMasks,
  } = useMasks(imageId || null);

  const { session } = useSession();
  const userId = session?.user.id || "";
  const {
    image,
    loading: imageLoading,
    processingError: imageError,
  } = useProcessedImageData(imageId || "", userId, 1000, 60000, 6);
  const { isModelLoaded, sendClickToModel } = useModel({
    w: width,
    h: height,
    imageEmbeddingUrl: image?.embedding_url || null,
    persistMask,
  });
  const toggleAnnotating = () => {
    setIsAnnotating((prevState) => !prevState);
    setSelectedMask(null);
  };

  const selectMask = (maskId: string) => {
    if (masks) {
      const selectedMask = masks.filter((mask) => mask.id === maskId);
      if (selectedMask.length === 1) {
        setSelectedMask(selectedMask[0]);
        setIsAnnotating(true);
      }
    }
  };

  if (image == null || !image || !image.is_processed) {
    return (
      <div className="h-[80vh] flex flex-col items-center justify-center">
        <div className="relative">
          <Spinner className="absolute top-[14px]" />
        </div>
        <LoadingBooksSVG />
      </div>
    );
  }

  return (
    <>
      {image && image.embedding_url && image.url && (
        <div>
          <div className="flex space-between h-[85vh] ">
            <PolygonMaskedImage
              removeMask={removeMask}
              sendClickToModel={sendClickToModel}
              setNaturalImageHeight={setHeight}
              setNaturalImageWidth={setWidth}
              src={`${S3_BUCKET_PREFIX}/${image.url}?test=ignore`}
              alt="test"
              polygons={masks}
              selectedMask={selectedMask}
              searchResultMasks={searchResultMasks}
            />

            <div className="flex flex-col mr-2 items-center">
              {/* TODO: swap to visibility instead because this render is expensive */}
              {!isAnnotating && (
                <Selection
                  masks={masks}
                  rotateMaskImage={rotateMaskImage}
                  removeMask={removeMask}
                  toggleAnnotating={toggleAnnotating}
                  selectMask={selectMask}
                  filterMasks={filterMasks}
                  searchResultMasks={searchResultMasks}
                />
              )}
              {isAnnotating && (
                <div></div>
                // <AnnotationFlow
                //   masks={masks || []}
                //   removeMask={removeMask}
                //   toggleAnnotate={toggleAnnotating}
                // />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProcessShelf;
