import React, { useContext, useState, FC, useEffect } from "react";
import { Mask } from "../hooks/useMasks";
import {
  Box,
  Button,
  CloseButton,
  IconButton,
  ScaleFade,
} from "@chakra-ui/react";
import { NUMBER_OF_BOOKS_TO_SELECT, S3_BUCKET_PREFIX } from "../constants";
import { fetchBooksByMaskId } from "../api/books";
import { supabase } from "../supabase";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

const variants = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};

interface IViewBook {
  book: any;
  toggleBookConfirmed: () => void;
  toggleAnnotate: () => void;
}
const ViewBook: React.FC<IViewBook> = ({
  book,
  toggleBookConfirmed,
  toggleAnnotate,
}) => {
  const [status, setStatus] = useState(book.status); // Read, Want to Read, Finished, Did not Finish
  const statusButtons = [
    { label: "Read", className: "rounded-l" },
    { label: "Want to Read", className: "border-x-0" },
    { label: "Did not Finish", className: "rounded-r" },
  ];

  return (
    <div className="flex flex-col align-center items-center justify-center h-[64vh] w-[36vw]">
      <BookCard title={book.title} author={book.author} cover={book.cover} />
      {book.review && (
        <div className="my-4 self-start ">
          <div className="text-lg font-bold">Review:</div>
          <div className="overflow-scroll max-h-[15vh]">
            <div className="italic">{book.review}</div>
          </div>
        </div>
      )}
      <div className="flex flex-wrap my-2 w-full justify-center">
        {statusButtons.map((s) => (
          <button
            key={s.label}
            type="button"
            className={`${
              status === s.label ? "bg-black text-white" : "bg-white"
            } border border-black px-4 py-2 w-[10vw] ${s.className}`}
            onClick={() => setStatus(s.label)}
          >
            {s.label}
          </button>
        ))}
      </div>
      <div className="flex justify-center items-center w-[36vw]">
        <div
          onClick={() => toggleBookConfirmed()}
          className="transition-colors duration-200 hover:bg-black hover:text-white cursor-pointer w-[15vw] p-2.5 my-4 border border-black rounded-sm text-center font-medium"
        >
          Change Book
        </div>
      </div>
    </div>
  );
};
interface IEmptyBookCard {
  onClick: () => void;
}

export const EmptyBookCard: React.FC<IEmptyBookCard> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center justify-center bg-white cursor-pointer w-full p-2.5 border border-gray-300 hover:border-black transition-colors duration-200"
    >
      <div className="flex items-center justify-center w-[60px] h-[80px] p-1">
        <FiPlusCircle size={30} />
      </div>
    </div>
  );
};

interface BookCardConfirmProps {
  title: string;
  author: string;
  cover: string;
  onConfirm: () => void;
  onDeny: () => void;
}

export const BookCardConfirm: React.FC<BookCardConfirmProps> = ({
  title,
  author,
  cover,
  onConfirm,
  onDeny,
}) => {
  return (
    <div className="relative bg-white w-full flex items-center justify-between p-2.5 m-1 border border-gray-300 hover:border-black transition-colors duration-200">
      <div className="w-full flex items-center justify-between">
        <div className={`flex items-center`}>
          <BookCover title={title} author={author} cover={cover} />
          <div className="ml-3">
            <h2 className="text-xl font-bold">{title}</h2>
            <p className="text-lg font-medium text-gray-600">{author}</p>
          </div>
        </div>
        <div className="flex">
          <AiOutlineCloseCircle
            size={40}
            className="fill-[#FF7575] cursor-pointer"
            onClick={onDeny}
          />
          <AiOutlineCheckCircle
            size={40}
            className="fill-[#93B9A0] cursor-pointer"
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};

interface IBookCard {
  title: string;
  author: string;
  cover: string;
  onClick?: () => void;
  onClose?: () => void;
}
export const BookCard: React.FC<IBookCard> = ({
  title,
  author,
  cover,
  onClick = () => {},
  onClose = null,
}) => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowCloseButton(true)}
      onMouseLeave={() => setShowCloseButton(false)}
      className="relative bg-white cursor-pointer w-full flex items-center justify-between p-2.5 border border-gray-300 hover:border-black transition-colors duration-200"
    >
      <div
        className="w-full flex items-center justify-between"
        onClick={onClick}
      >
        <div className={`flex items-center`}>
          <BookCover title={title} author={author} cover={cover} />
          <div className="ml-3">
            <h2 className="text-lg font-bold line-clamp-2">{title}</h2>
            <p className="text-md font-medium text-gray-600">{author}</p>
          </div>
        </div>
        {/* <BsThreeDotsVertical className="cursor-pointer" /> */}
      </div>
      {onClose && (
        <CloseButton
          onClick={onClose}
          backgroundColor={"white"}
          className={`shadow absolute -right-1 -top-1 transition-opacity duration-300 ${
            showCloseButton ? "opacity-100" : "opacity-0"
          } `}
        />
      )}
    </div>
  );
};

interface BigBookCoverProps {
  title: string;
  author: string;
  cover: string;
}
export const BigBookCover: React.FC<BigBookCoverProps> = ({
  title,
  author,
  cover,
}) => {
  const [useDefaultCover, setUseDefaultCover] = useState(false);

  return (
    <>
      {useDefaultCover ? (
        <div className="w-[120px] h-[160px] justify-between bg-gray-300 rounded-sm shadow-md flex flex-col justify-center items-center text-center p-1">
          <h1 className={`text-xs-sm font-bold text-black my-1 leading-none`}>
            {title}
          </h1>
          <h2
            className={`text-xxs-sm font-semibold mb-1 text-black leading-none`}
          >
            {author}
          </h2>
        </div>
      ) : (
        <img
          src={cover || ""}
          className="w-[120px] h-[160px] object-contain shadow-md"
          onError={() => setUseDefaultCover(true)}
        />
      )}
    </>
  );
};
interface BigBookCoverProps {
  title: string;
  author: string;
  cover: string;
}
const BookCover: React.FC<BigBookCoverProps> = ({ title, author, cover }) => {
  const [useDefaultCover, setUseDefaultCover] = useState(false);

  return (
    <>
      {useDefaultCover ? (
        <div className="w-[60px] h-[80px] justify-between bg-gray-300 rounded-sm shadow-md flex flex-col justify-center items-center text-center p-1">
          <h1
            className={`text-xs-sm font-bold text-black my-1 leading-none line-clamp-2`}
          >
            {title}
          </h1>
          <h2
            className={`text-xxs-sm font-semibold mb-1 text-black leading-none line-clamp-2`}
          >
            {author}
          </h2>
        </div>
      ) : (
        <img
          src={cover || ""}
          className="w-[60px] h-[80px] object-contain shadow-md"
          onError={() => setUseDefaultCover(true)}
        />
      )}
    </>
  );
};
interface IConfirmingBook {
  confirmingBook: any;
  handleConfirmBook: (review: string, status: string) => void;
  resetConfirmBook: () => void;
}
const ConfirmingBook: React.FC<IConfirmingBook> = ({
  confirmingBook,
  handleConfirmBook,
  resetConfirmBook,
}) => {
  const [review, setReview] = useState("");

  const statusButtons = [
    { label: "Read", className: "rounded-l" },
    { label: "Want to Read", className: "border-x-0" },
    { label: "Did not Finish", className: "rounded-r" },
  ];
  const [status, setStatus] = useState("Read"); // Read, Want to Read, Finished, Did not Finish
  const handleSubmit = () => {
    handleConfirmBook(review, status);
  };

  return (
    <form className="mt-3" onSubmit={handleSubmit}>
      <div className="flex flex-col align-center items-center justify-between space-between w-[36vw]">
        <BookCard
          title={confirmingBook.title}
          author={confirmingBook.author}
          cover={confirmingBook.cover}
        />
        <textarea
          className="p-3 border border-black rounded-sm m-1 w-full h-40 resize-none"
          id="review"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Enter Book Review (Optional)"
          autoFocus
        />
        <div className="flex flex-wrap my-2 w-full justify-center">
          {statusButtons.map((s) => (
            <button
              key={s.label}
              type="button"
              className={`${
                status === s.label ? "bg-black text-white" : "bg-white"
              } border border-black px-4 py-2 w-[10vw] ${s.className}`}
              onClick={() => setStatus(s.label)}
            >
              {s.label}
            </button>
          ))}
        </div>
        <div
          tabIndex={0}
          onClick={() => handleSubmit()}
          className="transition-colors duration-200 hover:bg-black hover:text-white cursor-pointer w-full p-2.5 my-4 border border-black rounded-sm text-center font-medium"
        >
          Confirm
        </div>
        <div
          onClick={() => resetConfirmBook()}
          className="transition-colors duration-200 hover:bg-black hover:text-white cursor-pointer w-[50%] p-2.5 my-4 border border-black rounded-sm text-center font-medium"
        >
          Go Back
        </div>
      </div>
    </form>
  );
};

interface IManualEntry {
  toggleManualEntry: () => void;
  onManualEntry: (title: string, author: string) => void;
}
const ManualEntry: React.FC<IManualEntry> = ({
  onManualEntry,
  toggleManualEntry,
}) => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");

  const handleSubmit = () => {
    onManualEntry(title, author);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="text-center font-bold font-large mb-2 mt-6">
        Input Your Book Manually
      </div>

      <div className="flex flex-col items-center justify-center w-[30vw]">
        <input
          className="p-3 border border-black rounded-sm m-1 w-full"
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter Book Title"
          autoFocus
        />
        <input
          className="p-3 border border-black rounded-sm m-1 w-full"
          type="text"
          id="author"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Enter Book Author"
        />
        <input
          className="p-3 border border-black rounded-sm m-1 w-full"
          type="text"
          id="publisher"
          // value={author}
          // onChange={(e) => setAuthor(e.target.value)}
          placeholder="Enter Book Publisher"
        />
        <input
          className="p-3 border border-black rounded-sm m-1 w-full"
          type="text"
          id="isbn"
          // value={author}
          // onChange={(e) => setAuthor(e.target.value)}
          placeholder="Enter Book ISBN"
        />

        <div
          onClick={() => handleSubmit()}
          className="transition-colors duration-200 hover:bg-black hover:text-white cursor-pointer w-full p-2.5 my-4 border border-black rounded-sm text-center font-medium"
        >
          Confirm
        </div>

        <div
          onClick={() => toggleManualEntry()}
          className="transition-colors duration-200 hover:bg-black hover:text-white cursor-pointer w-[50%] p-2.5 my-4 border border-black rounded-sm text-center font-medium"
        >
          Go Back
        </div>
      </div>
    </form>
  );
};

interface IBookGuesses {
  bookGuesses: any;
  onBookGuessChoice: (index: number) => void;
}
const BookGuesses: React.FC<IBookGuesses> = ({
  bookGuesses,
  onBookGuessChoice,
}) => {
  return (
    <>
      {bookGuesses && (
        <>
          {bookGuesses.length !== 0 ? (
            <div className="text-center text-xl font-bold mb-.5 mt-6">
              Select the Correct Book
            </div>
          ) : (
            <div>No Predicted Books</div>
          )}

          <div className="flex flex-col overflow-y-auto p-4">
            {bookGuesses?.map(
              (
                book: { cover: string; title: string; author: string },
                index: any
              ) => (
                <BookCard
                  onClick={() => onBookGuessChoice(index)}
                  title={book.title}
                  author={book.author}
                  cover={book.cover}
                />
              )
            )}
          </div>
        </>
      )}
    </>
  );
};

interface IAnnotateMask {
  mask: Mask;
  removeMask: (maskId: string) => void;
  toggleAnnotate: () => void;
  onConfirm: (maskId: string) => void;
}

const AnnotateMask: FC<IAnnotateMask> = ({
  mask,
  removeMask,
  toggleAnnotate,
  onConfirm,
}) => {
  const [book, setBook] = useState<any>(null);
  const [isManualEntry, setIsManualEntry] = useState(false);

  const [confirmingBook, setConfirmingBook] = useState<any>(null);

  const [isBookConfirmed, setIsBookConfirmed] = useState(false);

  const [bookGuesses, setBookGuesses] = useState<any | null>(null);

  useEffect(() => {
    async function getBooks() {
      const books = await fetchBooksByMaskId(mask.id);
      if (books) {
        const book = books[0];
        setBook(book);
        if (book.title || book.author) {
          setIsBookConfirmed(true);
        }
        if (book.prospective_books) {
          const prospectiveBooks = JSON.parse(
            book.prospective_books?.toString()
          );
          setBookGuesses(prospectiveBooks);
        }
      }
    }
    getBooks();
  }, [mask]);

  const resetConfirmBook = () => {
    setConfirmingBook(null);
  };

  const onManualEntry = (title: string, author: string) => {
    const payload = {
      title: title,
      author: author,
      cover: "",
    };
    setConfirmingBook(payload);
  };

  const onBookGuessChoice = (index: number) => {
    if (bookGuesses && bookGuesses[index]) {
      const selectedBook = bookGuesses[index];
      const payload = {
        title: selectedBook.title,
        author: selectedBook.author,
        cover: selectedBook.cover,
      };

      setConfirmingBook(payload);
    }
  };

  const onConfirmBook = (review: string, status: string) => {
    const payload = {
      ...confirmingBook,
      review,
      status,
    };
    async function writeSelectionToDB(payload: any) {
      console.log("Writing Selection");
      const { data, error } = await supabase
        .from("book_selection")
        .update(payload)
        .eq("mask_id", mask.id);

      console.log("Refetching Book");
      const books = await fetchBooksByMaskId(mask.id);
      if (books) {
        const book = books[0];
        setBook(book);
        setIsBookConfirmed(true);
      }
    }
    writeSelectionToDB(payload);
    onConfirm(mask.id);
  };
  const toggleManualEntry = () => {
    setIsManualEntry((prev) => !prev);
  };
  const toggleBookConfirmed = () => {
    setIsBookConfirmed((prev) => !prev);
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <img
          src={
            mask.maskImage
              ? URL.createObjectURL(mask.maskImage)
              : `${S3_BUCKET_PREFIX}/${mask.image_url}` || ""
          }
          alt="Book spine"
          className="w-[35vw] max-h-[10vw] object-contain rounded-lg mt-4"
        />
      </div>
      <div className="flex flex-col items-center max-h-[60vh] w-[40vw] justify-between overflow-y-scroll">
        {isBookConfirmed && (
          <ViewBook
            book={book}
            toggleBookConfirmed={toggleBookConfirmed}
            toggleAnnotate={toggleAnnotate}
          />
        )}
        {!isBookConfirmed && confirmingBook && (
          <ConfirmingBook
            confirmingBook={confirmingBook}
            handleConfirmBook={onConfirmBook}
            resetConfirmBook={resetConfirmBook}
          />
        )}
        {!isBookConfirmed && !confirmingBook && !isManualEntry && (
          <>
            <BookGuesses
              bookGuesses={bookGuesses}
              onBookGuessChoice={onBookGuessChoice}
            />
            <div className="mt-3">
              <div className="text-center font-semibold">
                Don't See Your Book?
              </div>
              <div className="flex w-[30vw] justify-between">
                <div className="cursor-pointer w-[14vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium">
                  Search
                </div>
                <div
                  onClick={() => toggleManualEntry()}
                  className="cursor-pointer w-[14vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                >
                  Manual Entry
                </div>
              </div>
            </div>
          </>
        )}
        {!isBookConfirmed && !confirmingBook && isManualEntry && (
          <ManualEntry
            onManualEntry={onManualEntry}
            toggleManualEntry={toggleManualEntry}
          />
        )}
      </div>
    </>
  );
};

export default AnnotateMask;
