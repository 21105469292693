import { canvasToFile } from "../utils/masks";
import { Book } from "../components/helpers/Interfaces";
import axios, { AxiosResponse } from "axios";
import { supabase } from "../supabase";
import { Database } from "../../types/supabase";
import Resizer from "react-image-file-resizer";
import { BACKEND_URL } from "../constants";

export type SupabaseMask = Database["public"]["Tables"]["masks"]["Row"];

export const resizeImage = (file: File, maxDimension = 2048) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      maxDimension,
      maxDimension,
      file.type,
      100,
      0, // rotation
      (uri) => {
        resolve(uri);
      },
      "file" // output type
    );
  });

export const rotateImageDueToWidth = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      "PNG",
      100,
      270,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export async function fetchMasks(imageId: string): Promise<SupabaseMask[]> {
  const { data, error } = await supabase
    .from("masks")
    .select("*")
    .eq("parent_image_id", imageId);

  console.log(data);
  if (error) throw error;

  return data;
}

interface BooksResponse {
  books: Book[];
}
export async function processMask(
  maskId: string,
  croppedCanvas: File
): Promise<Book[] | null> {
  try {
    const session = await supabase.auth.getSession();

    if (!session) {
      alert("Please log in to upload a file");
      return null;
    }
    const token = session.data.session?.access_token;

    if (!croppedCanvas) {
      console.error("Failed to convert canvas to file");
      return null;
    }

    const maskInfo = {
      mask_id: maskId,
    };
    const formData = new FormData();
    formData.append("file", croppedCanvas);
    formData.append("mask_info", JSON.stringify(maskInfo));

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response: AxiosResponse<BooksResponse> =
        await axios.post<BooksResponse>(`${BACKEND_URL}/masks`, formData, {
          headers,
        });
      return response.data.books;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
    return null;
  } catch (error) {
    alert("Error: ");
  } finally {
    return null;
  }
}

export async function saveCroppedMask(maskId: string, croppedMask: File) {
  try {
    const session = await supabase.auth.getSession();

    if (!session) {
      alert("Please log in to upload a file");
      return null;
    }
    const token = session.data.session?.access_token;

    if (!croppedMask) {
      console.error("Failed to convert canvas to file");
      return null;
    }

    const maskInfo = {
      mask_id: maskId,
    };
    const formData = new FormData();
    formData.append("file", croppedMask);
    formData.append("mask_info", JSON.stringify(maskInfo));

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response: AxiosResponse = await axios.post(
        `${BACKEND_URL}/crop-mask`,
        formData,
        {
          headers,
        }
      );
      return null;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
    return null;
  } catch (error) {
    alert("Error: ");
  } finally {
    return null;
  }
}
