import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingBooksSVG } from "../ProcessShelf";
import { Spinner } from "@chakra-ui/react";

interface BookLoadingProps {
  backgroundImageUrl: string;
}

export const BookLoadingComponent: FC<BookLoadingProps> = ({
  backgroundImageUrl,
}) => {
  const navigate = useNavigate();
  return (
    <div className="h-[90vh] flex flex-col items-center justify-center bg-[#F3F4F6]">
      <div className="flex flex-col w-[60vw] h-[50vh] p-4 bg-white rounded-lg shadow">
        <div className="text-center">
          <div className="text-xl font-bold">
            We are currently processing your shelf!
          </div>
          <div>We will send you an email when we are done.</div>
          <div className="flex w-full justify-center items-center">
            <div className="flex w-2/4 justify-between">
              <button
                onClick={() => navigate(`/upload`)}
                className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
              >
                Upload Another Shelf
              </button>
              <button
                onClick={() => navigate(`/login`)}
                className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
              >
                View All Shelves
              </button>
            </div>
          </div>
        </div>
        <div className="mt-3 relative w-full h-[25vh] flex items-center justify-center ">
          <img
            src={backgroundImageUrl}
            alt="photo of current bookshelf"
            className="absolute h-full w-full object-cover"
          />
        </div>
        <div className="flex items-center justify-center mt-4">
          <div className="relative">
            <LoadingBooksSVG />
            <Spinner className="absolute left-1/2 top-3/4 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        </div>
      </div>
    </div>
  );
};
