// Upload.tsx

import React, { useRef, useState } from "react";
import axios from "axios";
import { supabase } from "../supabase";
import { BACKEND_URL } from "../constants";
import { Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { resizeImage } from "../api/masks";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";

const UploadButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={61} fill="none">
    <circle cx={30} cy={30.27} r={30} fill="#1B1B1B" />
    <path
      fill="#fff"
      d="M31.414 13.856a2 2 0 0 0-2.828 0L15.858 26.584a2 2 0 1 0 2.828 2.828L30 18.1l11.314 11.313a2 2 0 0 0 2.828-2.828L31.414 13.856ZM32 45.27v-30h-4v30h4Z"
    />
  </svg>
);
const LoadingSpinner = () => (
  <svg
    className="animate-spin h-5 w-5 text-blue-500"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

function Upload() {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file to upload");
      return;
    }
    setUploading(true);
    try {
      const session = await supabase.auth.getSession();

      if (!session) {
        alert("Please log in to upload a file");
        return;
      }
      const token = session.data.session?.access_token;

      // Get the pre-signed URL from the backend
      const createResponse = await axios.post<{
        url: string;
        imageId: string;
      }>(
        BACKEND_URL + "/upload",
        {
          fileType: file.type,
          fileName: file.name,
          title,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const targetUrl = createResponse.data.url;
      const imageId = createResponse.data.imageId;
      // Upload the file to S3 using the pre-signed URL
      const resizedFile = await resizeImage(file, 3500);
      const result = await axios.put(targetUrl, resizedFile, {
        headers: {
          "Content-Type": file.type,
        },
      });
      navigate("/view-books/" + imageId);

      // Inform the user that the upload was successful
    } catch (error) {
      alert("Error: ");
    } finally {
      setUploading(false);
    }
  };
  const [backgroundImage, setBackgroundImage] = useState<string>("");

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(file ? file : null);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };
  return (
    <div className="flex items-start justify-center w-full md:h-[90vh] bg-[#F9F9FA]">
      <div className="mt-4 w-[80%] md:h-[90%] bg-white rounded-lg shadow p-4 sm:h-[99%] ">
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Upload Shelf
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                For the best results, follow these examples
              </p>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                <b>Good:</b>
                <img
                  className="h-[120px] w-[340px] object-cover rounded"
                  src="https://dfvghb4khovs4.cloudfront.net/bookshelves/09b48fa4-166d-439f-b468-750729b346aa-mir.jpg"
                />
                High resolution, book text is visible to the eye, books are
                directly in line with the camera
              </p>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                <b>Bad:</b>
                <img
                  className="h-[120px] w-[340px] object-cover rounded"
                  src="https://dfvghb4khovs4.cloudfront.net/bookshelves/8794e8cb-6d9e-4637-8acc-ce5e7bcffe62-zach-shinkar.jpeg"
                />
                Low Resolution, book spines not visible to the eye, books are
                offset from the camera
              </p>
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label
                  htmlFor="website"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Shelf Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      id="title"
                      value={title}
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Living Room #1"
                      onChange={handleTitleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  About this Shelf
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={2}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder={"This one is mostly classics..."}
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>

              <div className="col-span-full h-60">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Shelf Photo
                </label>
                <div
                  className="h-48 mt-2 flex justify-center rounded-lg border border-gray-900/25 px-6 py-10 bg-cover"
                  style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                  <div
                    className="text-center"
                    style={{
                      display: `${backgroundImage && "none"}`,
                    }}
                  >
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600 bg-cover">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={selectImage}
                          ref={fileInputRef}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
                {file && (
                  <div className="mt-1 flex justify-between">
                    Filename: {file.name}{" "}
                    <div>
                      <label className="ml-4 relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                        <span>Upload a Different Photo</span>
                        <input
                          onChange={selectImage}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={`mt-4 flex items-center justify-end gap-x-6`}>
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            disabled={backgroundImage.length === 0 || isUploading}
            className="w-32 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm disabled:bg-gray-600 disabled:cursor-not-allowed hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={uploadFile}
          >
            {isUploading ? <Spinner size={"sm"} /> : "Upload Shelf"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Upload;
