import React, { useEffect, useState, useRef } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import suggestion from "./suggestion";
import "./Editor.scss";
import { Mention } from "./mention-with-link";

export default ({ handleClick }) => {

	const editorRef = useRef();

	useEffect(() => {
		const editorElement = editorRef.current;
		if (editorElement) {
			editorElement.removeEventListener("click", handleClick);
			editorElement.addEventListener("click", handleClick);
		}
		return () => {
			if (editorElement) {
				editorElement.removeEventListener("click", handleClick);
			}
		};
	}, [handleClick]);

	const editor = useEditor({
		// editable: false,
		// onFocus({ editor }) {
		// 	setValue(editor.getJSON());
		// },

		// onUpdate({ editor }) {
		// 	setValue(editor.getJSON());
		// 	// The content has changed.
		// },
		extensions: [
			Document,
			Paragraph,
			Text,
			Mention.configure({
				HTMLAttributes: {
					class: "mention"
				},
				suggestion
			})
		],
		content: `
        <p>
            What do you all think about the new <span class="mention" data-type="author" data-id="1" data-label="James Joyce"></span> book?
        </p>
    `
	});

	return (
		<div ref={editorRef}>
			<EditorContent editor={editor} />
		</div>
	);
};
