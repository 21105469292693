import { supabase } from "../supabase";
import { Database } from "../../types/supabase";

import axios from "axios";
import { BACKEND_URL } from "../constants";

export type ImageData = Database["public"]["Tables"]["images"]["Row"];

export async function fetchImage(imageId: string): Promise<ImageData> {
  console.log("fetching image");
  const { data, error } = await supabase
    .from("images")
    .select("*")
    .eq("id", imageId)
    .single();

  if (error) throw error;

  return data!;
}

export async function fetchImages(userId: string): Promise<ImageData[]> {
  const { data, error } = await supabase
    .from("images")
    .select("*")
    .eq("owner_id", userId);

  console.log(data);
  if (error) throw error;

  return data!;
}

export async function uploadBigMask(
  bigMask: Uint8ClampedArray,
  parentImageId: string
) {
  try {
    const session = await supabase.auth.getSession();

    if (!session) {
      alert("Please log in to upload a file");
      return;
    }
    const token = session.data.session?.access_token;

    // Get the pre-signed URL from the backend
    const response = await axios.post<{ url: string }>(
      BACKEND_URL + "/upload-mask",
      {
        parentImageId: parentImageId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Upload the file to S3 using the pre-signed URL
    const result = await axios.put(response.data.url, bigMask.buffer, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });

    // Inform the user that the upload was successful
    console.log(result);
  } catch (error) {
    alert("Error: ");
  } finally {
  }
}

export async function getRawMasksForImage(imageId: string) {
  const mask_url = `https://bookstack-store.s3.amazonaws.com/bookshelves/${imageId}-masks`;

  try {
    const response = await axios.get(mask_url, {
      responseType: "arraybuffer",
    });

    const uint8ClampedArray = new Uint8ClampedArray(response.data);
    return uint8ClampedArray;
  } catch (error) {
    console.error("Error loading Uint8ClampedArray from S3:", error);
    return null;
    // throw error;
  }
}
