import React, { useEffect, useState } from "react";
import { SupabaseWrappedObject } from "../../api/wrapped";
import { BookCard } from "../Annotation";
import { GoogleBook } from "../annotation/BookSearch";
import { useNavigate } from "react-router-dom";
interface ViewWrappedChildProps {
  wrappedObject: SupabaseWrappedObject;
  showViewAll: boolean;
}

export const ViewWrappedCard: React.FC<ViewWrappedChildProps> = ({
  wrappedObject,
  showViewAll = false,
}) => {
  const navigate = useNavigate();
  const books = wrappedObject.books as any;
  console.log(books);
  return (
    <div className="flex w-[900px] border bg-white rounded-lg p-4">
      <div className="flex flex-col justify-between flex-grow h-full w-[450px] h-full">
        <div className="flex flex-col">
          <h1 className="mt-2 text-xl font-bold">{wrappedObject.header}</h1>
          <h2 className="mt-1 text-lg italic">{wrappedObject.sub_header}</h2>
          <p>{wrappedObject.summary}</p>
        </div>
        <div className="flex">
          <button
            onClick={() => navigate(`/wrapped/create`)}
            className="mr-2  z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
          >
            Create
          </button>
          {showViewAll && (
            <button
              onClick={() => navigate(`/wrapped`)}
              className=" z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
            >
              View all
            </button>
          )}
        </div>
      </div>

      <div className="ml-2">
        {books &&
          books.map(
            (book: { title: any; author: any; cover: any }, index: any) => {
              if (book.title || book.author) {
                return (
                  <div
                    className="w-[400px] h-[100px] my-2"
                    key={`book-${index}`}
                  >
                    <BookCard
                      key={`book-${index}`}
                      title={book.title || ""}
                      cover={book.cover || ""}
                      author={book.author || ""}
                      // onClick={() => {
                      //   addBook(book);
                      // }}
                    />
                  </div>
                );
              }
            }
          )}
      </div>
    </div>
  );
};
