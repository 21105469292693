import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { processMask, SupabaseMask } from "../api/masks";
import { supabase } from "../supabase";
import { S3_BUCKET_PREFIX } from "../constants";

export interface Mask extends SupabaseMask {
  maskImage: File | null;
}

export const useMasks = (imageId: string | null) => {
  const [masks, setMasks] = useState<Mask[] | null>(null);
  const [searchResultMasks, setSearchResultMasks] = useState<Mask[] | null>(
    null
  );

  function reloadMasks() {
    async function loadMasks() {
      if (imageId) {
        const { data, error } = await supabase
          .from("masks")
          .select("*")
          .eq("parent_image_id", imageId);

        if (error) {
          console.error("Error fetching masks from supabase:", error);
        }
        if (data) {
          const masksWithDefaultMaskImage = data.map((mask) => ({
            ...mask,
            maskImage: null,
          }));
          masksWithDefaultMaskImage.map((mask) => {
            const test = mask.mask_polygon as Array<any>;
            console.log(test);
          });
          setMasks(masksWithDefaultMaskImage);
        }
      }
    }
    loadMasks();
  }

  useEffect(() => {
    if (!imageId) {
      return;
    }
    async function getMasks() {
      if (imageId) {
        const { data, error } = await supabase
          .from("masks")
          .select("*")
          .eq("parent_image_id", imageId);

        if (error) {
          console.error("Error fetching masks from supabase:", error);
        }
        if (data) {
          const masksWithDefaultMaskImage = data.map((mask) => ({
            ...mask,
            maskImage: null,
          }));
          masksWithDefaultMaskImage.map((mask) => {
            const test = mask.mask_polygon as Array<any>;
            console.log(test);
          });
          setMasks(masksWithDefaultMaskImage);
        }
      }
    }
    getMasks();
  }, [imageId]);

  const removeMask = (maskIdToRemove: string) => {
    if (!imageId || !masks) {
      return;
    }

    async function removeMaskFromDB() {
      const { data, error } = await supabase
        .from("masks")
        .delete()
        .match({ parent_image_id: imageId, id: maskIdToRemove });
    }
    removeMaskFromDB();
    setMasks((prevMasks) => {
      if (prevMasks) {
        return prevMasks?.filter((mask) => mask.id !== maskIdToRemove) || null;
      } else {
        return null;
      }
    });
  };

  const rotateMaskImage = (maskId: string, flipMask = false) => {
    if (!masks) {
      return;
    }

    async function writeSelectionToDB(payload: any) {
      console.log("writing", maskId, payload);
      const supabaseResponse = await supabase
        .from("masks")
        .update(payload)
        .eq("id", maskId);
    }

    async function rotateImage(selectedMask: Mask) {
      const payload = {
        should_flip_mask: flipMask,
      };
      setMasks((prevMasks) => {
        if (!prevMasks) {
          return null;
        } else {
          return prevMasks.map((mask) => {
            if (mask.id == selectedMask.id) {
              return { ...mask, should_flip_mask: flipMask };
            } else {
              return mask;
            }
          });
        }
      });

      await writeSelectionToDB(payload);
    }
    const selectedMask = masks?.filter((mask) => mask.id === maskId);
    if (selectedMask) {
      rotateImage(selectedMask[0]);
    }
  };

  const persistMask = (
    croppedMaskImageElement: File,
    simplifiedPolygonMask: any
  ) => {
    if (!imageId) {
      return;
    }
    var maxMaskNumber = 0;
    masks?.forEach((m) => {
      if (m.mask_number) {
        maxMaskNumber = Math.max(maxMaskNumber, m.mask_number);
      }
    });
    const maskNumber = maxMaskNumber + 1;

    const maskId = uuidv4();
    const imageUrl = `bookshelves/masks/${maskId}.png`;
    const currentDate = new Date();
    const supabaseMask = {
      parent_image_id: imageId,
      created_at: null,
      id: maskId,
      mask_number: maskNumber,
      image_url: imageUrl,
      mask_polygon: simplifiedPolygonMask,
      should_flip_mask: false,
      crop_image_url: "",
    };

    const mask = { ...supabaseMask, maskImage: croppedMaskImageElement };
    const newMasks = masks ? [mask, ...masks] : [mask];
    setMasks(newMasks);

    async function writeMaskToDB() {
      //TODO: need to decouple this
      const supabaseResponse = await supabase
        .from("masks")
        .insert(supabaseMask);
      const response = await processMask(maskId, croppedMaskImageElement);
      //TODO: possible that supabase response fails
      console.log("Supabase Mask Write:", supabaseResponse);
      // add error handling
    }
    writeMaskToDB();
    return maskId;
  };

  const cropMask = (maskId: string, file: File) => {
    setMasks((prevMasks) => {
      if (!prevMasks) {
        return null;
      } else {
        return prevMasks.map((mask) => {
          if (mask.id == maskId) {
            return { ...mask, maskImage: file };
          } else {
            return mask;
          }
        });
      }
    });
  };

  const filterMasks = (searchText: string) => {
    async function searchMasks() {
      if (imageId) {
        const { data, error } = await supabase.rpc(
          "fetch_books_by_imageid_and_search",
          { imageid: imageId, searchquery: searchText }
        );
        if (error) throw error;
        if (data && masks) {
          const maskIds = data.map((value) => value.mask_id);
          if (maskIds.length > 0) {
            const filteredMasks = masks.filter((mask) => {
              return maskIds.includes(mask.id);
            });
            console.log(filteredMasks);
            setSearchResultMasks(filteredMasks);
          }
        }
      }
    }
    if (!searchText) {
      setSearchResultMasks(null);
    } else {
      searchMasks();
    }
  };

  return {
    masks,
    persistMask,
    removeMask,
    filterMasks,
    rotateMaskImage,
    searchResultMasks,
    reloadMasks,
    cropMask,
  };
};
