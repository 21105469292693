import React, { useState, useContext, ReactElement, ReactNode } from "react";

export enum PageType {
  AUTHOR = "author",
  BOOK = "book",
  SHELF = "shelf",
}

export type PageData = {
  id: string;
  type: PageType;
  title: string;
  content: ReactElement;
};

interface PagesContextProps {
  pages: PageData[];
  addPage: (title: string) => void;
  removePage: (index: number) => void;
}

// Create the context
const PagesContext = React.createContext<PagesContextProps | undefined>(
  undefined
);

const firstPage = {
  id: "123",
  type: PageType.SHELF,
  content: <div></div>,
  title: "Test Title",
} as PageData;

const secondPage = {
  id: "1234",
  type: PageType.BOOK,
  content: <div></div>,
  title: "Finnegan's Wake",
} as PageData;

interface PagesProviderProps {
  children: React.ReactNode;
}

// Create the provider component
export const PagesProvider: React.FC<PagesProviderProps> = ({ children }) => {
  const [pages, setPages] = useState<PageData[]>([firstPage, secondPage]);

  const addPage = (title: string) => {
    const maxPageNum = Math.max(...pages.map((val, index) => index));
    let newPage = {
      id: "456",
      type: PageType.AUTHOR,
      content: <div>{title}</div>,
      title: `${title} ${maxPageNum}`,
    };
    setPages((prevData) => [...prevData, newPage]);
  };

  const removePage = (index: number) => {
    setPages((prevData) => prevData.filter((_, i) => i !== index));
  };

  return (
    <PagesContext.Provider value={{ pages, addPage, removePage }}>
      {children}
    </PagesContext.Provider>
  );
};

// Create a custom hook to use this context
export const usePages = (): PagesContextProps => {
  const context = useContext(PagesContext);
  if (!context) {
    throw new Error("usePages must be used within a PagesProvider");
  }
  return context;
};
