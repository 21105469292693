import React, { useEffect, useState, FC, useRef } from "react";
import { useSession } from "../context/hooks/useSession";
import { SupabaseBook } from "../api/books";
import {
  addBookToStory,
  fetchBooksByStoryId,
  fetchImageUrlFromBookSelection,
  fetchImageUrlandImageIdFromBookSelection,
  getStoryForUserId,
} from "../api/stories";
import { BookCard, EmptyBookCard } from "./Annotation";
import { S3_BUCKET_PREFIX } from "../constants";
import {
  SupabaseQuestionAndAnswer,
  getQandAsForStoryId,
} from "../api/questions";
import { motion, useMotionValue } from "framer-motion";
import { FaUserCircle, FaHome, FaSearch } from "react-icons/fa";

import PolygonMaskedImage from "./PolygonMaskedImage";
import ViewMaskedImage from "./ViewMaskedImage";
import { useMasks, Mask } from "../hooks/useMasks";
import Sidebar, { BottomSidebar } from "./helpers/SideBar";

// Define possible positions
export enum Position {
  CENTER = "center",
  CENTER_LEFT = "center-left",
  CENTER_RIGHT = "center-right",
}

function StackStory() {
  const [hoveredId, setHoveredId] = useState<number | null>(0);

  const [bookInfoPosition, setBookInfoPosition] = useState(Position.CENTER);
  const constraintsRef = useRef(null);

  const setPosition = (position: Position) => {
    setBookInfoPosition(position);
  };

  const getDragConstraints = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const elementWidth = vw * 0.4; // 40vw
    const elementHeight = vh * 0.9; // 90vh
    let left = 0;
    let top = 0;
    let rightBuffer = 40;
    let leftBuffer = 120;
    switch (bookInfoPosition) {
      case Position.CENTER:
        left = (vw - elementWidth) / 2;
        top = (vh - elementHeight) / 2;
        break;
      case Position.CENTER_LEFT:
        left = 0 + leftBuffer;
        top = (vh - elementHeight) / 2;
        break;
      case Position.CENTER_RIGHT:
        left = vw - elementWidth - rightBuffer;
        top = (vh - elementHeight) / 2;
        break;
    }
    return { left, top };
  };
  const { left, top } = getDragConstraints();

  const [storyId, setStoryId] = useState("");
  const [imageId, setImageId] = useState("");
  const [showShelfInfo, setShowShelfInfo] = useState(true);
  const [backgroundImgUrl, setBackgroundImgUrl] = useState("");
  const [qAndA, setQAndA] = useState<SupabaseQuestionAndAnswer[] | null>(null);

  const [storyBooks, setStoryBooks] = useState<SupabaseBook[] | null>(null);
  const [highlightedBookIdx, setHighlightedBookIdx] = useState<number | null>(
    null
  );
  const { session } = useSession();
  const userId = session?.user.id || "";
  const { masks } = useMasks(imageId || null);
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);

  const toggleShelfInfo = () => {
    setShowShelfInfo((prev) => !prev);
  };

  useEffect(() => {
    async function getBooks() {
      const story = await getStoryForUserId(userId);
      if (story) {
        setStoryId(story.id);
      }
    }
    getBooks();
  }, []);

  useEffect(() => {
    async function getStoryBooks() {
      if (storyId) {
        const stories = await fetchBooksByStoryId(storyId);
        setStoryBooks(stories);
        const data = await getQandAsForStoryId(storyId);
        setQAndA(data);
      }
    }
    getStoryBooks();
  }, [storyId]);

  useEffect(() => {
    async function getBackgroundUrl(bookId: string) {
      const data = await fetchImageUrlandImageIdFromBookSelection(bookId);
      setBackgroundImgUrl(data.image_url);
      setImageId(data.image_id);
    }
    if (
      storyBooks &&
      storyBooks.length > 0 &&
      highlightedBookIdx !== null &&
      !(highlightedBookIdx < 0) &&
      highlightedBookIdx < storyBooks.length
    ) {
      const highlightedBook = storyBooks[highlightedBookIdx];
      getBackgroundUrl(highlightedBook.id);
    }
  }, [highlightedBookIdx, storyBooks]);

  useEffect(() => {
    if (storyBooks && storyBooks.length > 0) {
      setHighlightedBookIdx(0);
    }
  }, [storyBooks]);

  const nextBook = () => {
    setHighlightedBookIdx((prev) => {
      if (prev !== null && storyBooks) {
        if (prev == storyBooks.length - 1) {
          return 0;
        }
        return prev + 1;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    if (!showShelfInfo && storyBooks && highlightedBookIdx !== null && masks) {
      const selectedMaskId = storyBooks[highlightedBookIdx].mask_id;
      const selectedMask = masks.filter((m) => m.id == selectedMaskId)[0];
      setSelectedMask(selectedMask);
      scrollToMask(selectedMaskId || "");
    }
  }, [highlightedBookIdx, storyBooks, masks, showShelfInfo]);

  // useEffect(() => {
  //   if (!showShelfInfo) {
  //     if (storyBooks && highlightedBookIdx) {
  //       const selectedMaskId = storyBooks[highlightedBookIdx].mask_id;
  //       alert(selectedMaskId);

  //       scrollToMask(selectedMaskId || "");
  //     }
  //   }
  // }, [showShelfInfo, storyBooks, highlightedBookIdx]);

  const viewBooks = () => {
    setShowShelfInfo(false);
    if (storyBooks && highlightedBookIdx !== null) {
      const selectedMaskId = storyBooks[highlightedBookIdx].mask_id;

      scrollToMask(selectedMaskId || "");
    }
  };

  const scrollToMask = (maskId: string) => {
    const section = document.querySelector(`#mask-${maskId}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="w-[100vw] h-auto overflow-y-auto" ref={constraintsRef}>
      <BottomSidebar
        masksStatus={[]}
        isHorizontal={false}
        toggleLayout={() => {}} //TODO
        hoveredId={hoveredId}
        setHoveredId={setHoveredId}
        selectMask={() => {}}
      />
      <div className="absolute">
        <ViewMaskedImage
          src={`${S3_BUCKET_PREFIX}/${backgroundImgUrl}`}
          alt="test"
          polygons={masks}
          selectedMask={selectedMask}
          onMaskClick={scrollToMask}
          setPosition={setPosition}
        />
      </div>
      <motion.div
        drag
        initial={{ x: left, y: top }} // start in the center
        animate={{
          x: left,
          y: top,
        }}
        dragConstraints={{
          left: -window.innerWidth * 0.2, // 20% of the viewport width
          right: window.innerWidth * 0.8, // 20% of the viewport width
          top: -window.innerHeight * 0.45, // 45% of the viewport height
          bottom: window.innerHeight * 0.45, // 45% of the viewport height
        }}
        transition={{ type: "spring", stiffness: 30, damping: 10 }}
        className={`fixed z-10 flex flex-col items-center justify-center m-2 p-8 w-[40vw] max-h-[90vh] bg-white rounded-2xl`}
      >
        {showShelfInfo && (
          <>
            <div className="font-bold text-xl mb-3 ">Your Books</div>
            {qAndA &&
              qAndA.map((questionAnswer) => {
                return (
                  <div className="mb-2 w-full">
                    <div className="font-bold mb-1">
                      {questionAnswer.question}
                    </div>
                    <div>{questionAnswer.answer}</div>
                  </div>
                );
              })}
            <div
              onClick={viewBooks}
              className="cursor-pointer w-[30vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
            >
              View Books
            </div>
          </>
        )}
        {!showShelfInfo && (
          <>
            <div className="w-[30vw]">
              {storyBooks && highlightedBookIdx !== null && (
                <>
                  <BookCard
                    title={storyBooks[highlightedBookIdx].title || ""}
                    author={storyBooks[highlightedBookIdx].author || ""}
                    cover={storyBooks[highlightedBookIdx].cover || ""}
                  />
                  <div className="flex flex-col justify-center items-center">
                    <div className="font-bold font-large">Review</div>
                    <div>{storyBooks[highlightedBookIdx].review}</div>
                  </div>
                </>
              )}
            </div>
            <div
              onClick={() => nextBook()}
              className="cursor-pointer w-[30vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
            >
              Next Highlight
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
}

export default StackStory;
