import React, { useEffect, useState } from "react";
import {
  SupabaseBook,
  fetchBooksByImageId,
  fetchBooksByMaskId,
} from "../api/books";
import { GoogleBook } from "../components/annotation/BookSearch";

import { supabase } from "../supabase";

export const useBooks = (imageId: string) => {
  const [books, setBooks] = useState<SupabaseBook[] | null>(null);
  useEffect(() => {
    async function getBooks() {
      if (imageId) {
        const data = (await fetchBooksByImageId(imageId)) as SupabaseBook[];
        setBooks(data);
      }
    }
    getBooks();
  }, [imageId]);

  const addBookForMaskId = (book: GoogleBook, maskId: string) => {
    async function addBook(payload: any) {
      // convoluted: Upserts both the db and the existing books state

      const existingBook = await fetchBooksByMaskId(maskId);

      let newState = { ...payload };
      const tmpId = `tmp-book-${maskId}`;
      if (existingBook && existingBook[0]) {
        const oldBookId = existingBook[0].id;
        const { data, error } = await supabase
          .from("book_selection")
          .update({ ...payload })
          .eq("id", oldBookId)
          .select();
        newState = { ...newState, id: oldBookId };
      } else {
        const { data, error } = await supabase
          .from("book_selection")
          .insert([payload]);
        newState = { ...newState, id: tmpId };
      }

      setBooks((prev) => {
        if (!prev) {
          return [newState];
        } else {
          if (prev.find((val) => val.id === newState.id)) {
            return prev.map((val) => {
              if (val.id === newState.id) {
                return newState;
              } else {
                return val;
              }
            });
          } else {
            return [...prev, newState];
          }
        }
      });
    }
    const payload = {
      mask_id: maskId,
      title: book.title,
      author: book.author,
      cover: book.cover,
      isbn: book.isbn_10,
    };
    console.log(book);
    addBook(payload);
  };
  return { books, addBookForMaskId };
};
