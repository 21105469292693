import { useState, useEffect, useRef } from "react";
import { useScrollPosition } from "../../../hooks/basics/useScrollPosition";
import { PageData } from "../context/pageContext";

export const PAGE_OFFSET_PX = 40;

export const usePagePositions = (pages: PageData[]) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [pagePositions, setPagePositions] = useState<number[]>([]);
  const mainScrollRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollPos = useScrollPosition(mainScrollRef);

  useEffect(() => {
    pageRefs.current = pageRefs.current.slice(0, pages.length);
    const newPositions = pageRefs.current.map((headerRef, index) =>
      headerRef ? headerRef.offsetLeft - (index + 1) * PAGE_OFFSET_PX : 0
    );
    setPagePositions(newPositions.slice(0, pages.length - 1));
  }, []);

  useEffect(() => {
    pageRefs.current = pageRefs.current.slice(0, pages.length);
    const newPositions = pageRefs.current.map((headerRef, index) => {
      console.log(headerRef?.offsetLeft);
      return headerRef
        ? headerRef.offsetLeft - (index + 1) * PAGE_OFFSET_PX
        : 0;
    });
    // TODO: handle deletion
    setPagePositions((prev) => {
      return [...prev, newPositions[pages.length - 1]];
    });
    if (mainScrollRef.current) {
      mainScrollRef.current.scrollBy({ left: 10000, behavior: "smooth" });
    }
  }, [pages]);

  useEffect(() => {
    const activeIndex = pagePositions.findIndex((pos, index) => {
      if (index !== pagePositions.length - 1) {
        return scrollPos >= pos && scrollPos < pagePositions[index + 1];
      }
      return scrollPos >= pos;
    });
    setActivePageIndex(activeIndex);
  }, [scrollPos, pagePositions]);

  const scrollToIndex = (index: number) => {
    if (mainScrollRef.current) {
      mainScrollRef.current?.scrollBy({
        left: -(scrollPos - pagePositions[index]),
        behavior: "smooth",
      });
    }
  };

  return {
    mainScrollRef,
    pageRefs,
    activePageIndex,
    scrollToIndex,
    scrollPos,
  };
};
