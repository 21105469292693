import React, { useEffect, useRef, useState } from "react";
import { Mask } from "../../hooks/useMasks";
import { SupabaseBook } from "../../api/books";

export interface Point {
  x: number;
  y: number;
}

interface PolygonMaskedImageProps {
  src: string;
  alt: string;

  polygons: Mask[] | null; // Array of polygon points
  selectedMask: Mask | null;
  searchResultMasks: Mask[] | null;

  setSelectedMask: (mask: Mask | null) => void;
  setImageDisplayHeight: (value: number) => void;
  setImageDisplayWidth: (value: number) => void;

  books: SupabaseBook[] | null;

  showLabels: boolean;
}

export const BooksWithMasks: React.FC<PolygonMaskedImageProps> = ({
  src,
  alt,
  polygons,
  selectedMask,
  searchResultMasks,
  setSelectedMask,
  setImageDisplayHeight,
  setImageDisplayWidth,
  books,
  showLabels,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const polygonRefs = useRef<(SVGPolygonElement | null)[]>([]);
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [hoveredPolygonIndex, setHoveredPolygonIndex] = useState<number | null>(
    null
  );

  const masksToShow = searchResultMasks ? searchResultMasks : polygons;
  useEffect(() => {
    if (imageRef.current) {
      setImageDimensions({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
      });
    }
  }, [imageRef]);

  useEffect(() => {
    if (imageRef.current) {
      // inefficient, but can't figure out where it shoudl be to
      // enable resize on first render
      const rect = imageRef.current.getBoundingClientRect();
      setWidth(rect.width);
      setHeight(rect.height);
    }
    if (imageDimensions && width && height && masksToShow) {
      // Calculate new points for each polygon

      const newPolygons = masksToShow.map((mask) => {
        const dbPolygons = mask?.mask_polygon as Array<any>;
        return dbPolygons?.map(({ x, y }) => ({
          x: (x / imageDimensions.width) * width,
          y: (y / imageDimensions.height) * height,
        }));
      });

      if (polygonRefs.current) {
        newPolygons.forEach((newPoints, index) => {
          if (polygonRefs.current && polygonRefs.current[index]) {
            polygonRefs.current[index]?.setAttribute(
              "points",
              newPoints.map(({ x, y }) => `${x},${y}`).join(" ")
            );
          }
        });
      }
    }
  }, [imageDimensions, width, height, polygons, selectedMask, masksToShow]);

  const handleImageLoad = () => {
    if (imageRef.current && svgRef.current) {
      setImageDimensions({
        width: imageRef.current.naturalWidth,
        height: imageRef.current.naturalHeight,
      });
      const aspectRatio =
        imageRef.current.naturalWidth / imageRef.current.naturalHeight;
      const maxWidth = window.innerWidth * 0.5;
      const maxHeight = window.innerHeight * 0.7;
      let newWidth = maxWidth;
      let newHeight = newWidth / aspectRatio;

      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * aspectRatio;
      }

      imageRef.current.style.width = `${newWidth}px`;
      imageRef.current.style.height = `${newHeight}px`;

      svgRef.current.style.width = `${newWidth}px`;
      svgRef.current.style.height = `${newHeight}px`;
      svgRef.current.style.position = "absolute";
      svgRef.current.style.top = imageRef.current.offsetTop + "px";
      svgRef.current.style.left = imageRef.current.offsetLeft + "px";
      setImageDisplayHeight(newHeight);
      setImageDisplayWidth(newWidth);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        setWidth(rect.width);
        setHeight(rect.height);
      }
      if (imageRef.current && svgRef.current) {
        const aspectRatio =
          imageRef.current.naturalWidth / imageRef.current.naturalHeight;
        const maxWidth = window.innerWidth * 0.5;
        const maxHeight = window.innerHeight * 0.7;
        let newWidth = maxWidth;
        let newHeight = newWidth / aspectRatio;

        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }

        imageRef.current.style.width = `${newWidth}px`;
        imageRef.current.style.height = `${newHeight}px`;

        imageRef.current.style.width = `${newWidth}px`;
        imageRef.current.style.height = `${newHeight}px`;

        svgRef.current.style.width = `${newWidth}px`;
        svgRef.current.style.height = `${newHeight}px`;
        svgRef.current.style.position = "absolute";
        svgRef.current.style.top = imageRef.current.offsetTop + "px";
        svgRef.current.style.left = imageRef.current.offsetLeft + "px";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handleResize initially to ensure correct dimensions are calculated

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const img = e.target as HTMLImageElement;
    const rect = img.getBoundingClientRect();
    const scaleX = img.naturalWidth / img.clientWidth;
    const scaleY = img.naturalHeight / img.clientHeight;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    // sendClickToModel({ x: x, y: y, clickType: 1 }, img);

    console.log(`Clicked at (x: ${x}, y: ${y}) on the original image.`);
  };

  const handlePolygonClick = (mask: Mask) => () => {
    if (selectedMask && selectedMask.id === mask.id) {
      setSelectedMask(null);
    } else {
      setSelectedMask(mask);
    }
    const test = books?.find((val) => val.mask_id === mask.id);
    console.log(test);
  };

  return (
    <div className="flex items-center justify-center ">
      <img
        src={src}
        alt={alt}
        className="rounded-bl-lg"
        ref={imageRef}
        onLoad={handleImageLoad}
        onClick={handleImageClick}
        crossOrigin="anonymous"
        style={{ userSelect: "none" }}
      />
      <svg
        ref={svgRef}
        className="pointer-events-none"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient
            id="shimmer-gradient-blue"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <animate
              attributeName="x1"
              from="-100%"
              to="200%"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="x2"
              from="0%"
              to="300%"
              dur="2s"
              repeatCount="indefinite"
            />
            <stop offset="0%" stopColor="rgba(255, 255, 255, 0.1)" />
            <stop offset="50%" stopColor="rgba(255, 255, 255, 0.5)" />
            <stop offset="100%" stopColor="rgba(255, 255, 255, 0.1)" />
          </linearGradient>
          <linearGradient
            id="shimmer-gradient-green"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <animate
              attributeName="x1"
              from="-100%"
              to="200%"
              dur="8s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="x2"
              from="0%"
              to="300%"
              dur="8s"
              repeatCount="indefinite"
            />
            <stop offset="0%" stopColor="rgba(0, 128, 0, 0.1)" />
            <stop offset="50%" stopColor="rgba(0, 128, 0, 0.5)" />
            <stop offset="100%" stopColor="rgba(0, 128, 0, 0.1)" />
          </linearGradient>

          <linearGradient
            id="shimmer-gradient-red"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <animate
              attributeName="x1"
              from="-100%"
              to="200%"
              dur="8s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="x2"
              from="0%"
              to="300%"
              dur="8s"
              repeatCount="indefinite"
            />
            <stop offset="0%" stopColor="rgba(255, 0, 0, 0.1)" />
            <stop offset="50%" stopColor="rgba(255, 0, 0, 0.5)" />
            <stop offset="100%" stopColor="rgba(255, 0, 0, 0.1)" />
          </linearGradient>
        </defs>
        {masksToShow?.map((mask, index) => {
          const points = mask.mask_polygon as Array<any>;

          const isBookRead =
            books?.find((val) => val.mask_id === mask.id)?.status === "read";
          const isSelected = mask.id === (selectedMask?.id || "");
          return (
            <polygon
              key={index}
              ref={(el) => (polygonRefs.current[index] = el)}
              points={points.map((p) => `${p.x},${p.y}`).join(" ")}
              fill={
                isSelected
                  ? "url(#shimmer-gradient-blue)"
                  : isBookRead
                  ? "url(#shimmer-gradient-green)"
                  : "url(#shimmer-gradient-red)"
              }
              stroke={isSelected ? "blue" : isBookRead ? "green" : "red"}
              strokeWidth="1"
              onClick={handlePolygonClick(mask)}
              pointerEvents="all"
              className={`cursor-pointer ${
                hoveredPolygonIndex === index ||
                searchResultMasks ||
                mask.id === (selectedMask?.id || "") ||
                showLabels
                  ? "opacity-100"
                  : "opacity-0"
              }`} // Update this line
              onMouseEnter={() => setHoveredPolygonIndex(index)} // Add this line
              onMouseLeave={() => setHoveredPolygonIndex(null)}
            />
          );
        })}
      </svg>
    </div>
  );
};
