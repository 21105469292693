import { Mask } from "../hooks/useMasks";
import { SupabaseBook } from "../api/books";

import { saveAs } from "file-saver";

type DictionaryType = { [key: string]: any }[];

interface IGenerateCSV {
  masks: Mask[] | null;
  books: SupabaseBook[] | null;
}

export function generateCSV(csvData: IGenerateCSV): DictionaryType {
  const { books, masks } = csvData;
  if (!books || !masks) {
    return [];
  }

  const csvRows: DictionaryType = masks.reduce((acc: DictionaryType, mask) => {
    const bookSelection = books.find((val) => val.mask_id === mask.id);
    if (bookSelection) {
      if (bookSelection.isbn) {
        acc.push({
          title: bookSelection.title || "",
          author: bookSelection.author || "",
          isbn: bookSelection.isbn || "",
        });
      }
    }
    return acc;
  }, [] as DictionaryType);

  const data = csvRows;
  const fileName = "bookstack-export.csv";

  const replacer = (key: string, value: any) => (value === null ? "" : value); // specify how to handle null values here
  const header = Object.keys(data[0]);
  const csv = [
    header.join(","), // header row first
    ...data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, fileName);

  return csvRows;
}
