import { useState, useEffect, RefObject } from "react";

export const useScrollPosition = (divRef: RefObject<HTMLDivElement>) => {
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const { scrollLeft } = divRef.current;
        setScrollPos(scrollLeft);
      }
    };

    if (divRef.current) {
      divRef.current.addEventListener("scroll", handleScroll);

      return () => {
        if (divRef.current) {
          divRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [divRef]);

  return scrollPos;
};
