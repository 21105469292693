import React, { useState, FC } from "react";
import { useSession } from "../../context/hooks/useSession";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { S3_BUCKET_PREFIX } from "../../constants";
import { BookCard } from ".././Annotation";
import Search from ".././basics/Search";
import { SupabaseMask } from "../../api/masks";

export interface GoogleBook {
  title: string;
  author: string;
  cover: string;
  isbn_10: string;
  isbn_13: string;
}

interface BookSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectBook: (book: GoogleBook, maskId: string) => void;
  mask: SupabaseMask | null;
}

export const BookSearchModal: FC<BookSearchModalProps> = ({
  isOpen,
  onClose,
  selectBook,
  mask,
}) => {
  const [books, setBooks] = useState<GoogleBook[] | null>(null);
  const { session } = useSession();
  const userId = session?.user.id || "";

  return (
    <div>
      {mask && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Search for Book</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="h-[75vh]">
                <div className="flex flex-col h-full items-center ">
                  <img
                    src={`${S3_BUCKET_PREFIX}/${mask.image_url}`}
                    className="max-h-[300px] object-contain rounded"
                  />
                  <div className="w-5/6">
                    <Search setResults={setBooks} />
                  </div>

                  <div className="overflow-scroll">
                    {books &&
                      books.map((book, index) => {
                        if (book.title || book.author) {
                          return (
                            <div className="w-11/12" key={`book-${index}`}>
                              <BookCard
                                title={book.title || ""}
                                cover={book.cover || ""}
                                author={book.author || ""}
                                onClick={() => {
                                  selectBook(book, mask.id);
                                  onClose();
                                }}
                                key={`book-${index}`}
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
