// Login.js
import { createClient, Session, SupabaseClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import React from "react";
import { Button } from "@chakra-ui/react";
import { useSession } from "../context/hooks/useSession";
import { supabase } from "../supabase";
import UserImages from "./UserImages";

export const LogoutButton = () => {
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.error("Error logging out:", error.message);
    } else {
      console.log("Logged out successfully");
    }
  };

  return (
    <div className="cursor-pointer" onClick={handleLogout}>
      Logout
    </div>
  );
};

export default function Login() {
  const { session } = useSession();

  if (!session) {
    return (
      <div className="flex items-center justify-center w-80vw h-90vh">
        <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center w-80vw h-90vh">
        {/* <LogoutButton supabaseClient={supabase} /> */}
        <UserImages userId={session.user.id} />
      </div>
    );
  }
}
