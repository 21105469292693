import { createContext, useContext } from 'react';
import { useState, useEffect } from 'react'
import { createClient, Session, SupabaseClient } from '@supabase/supabase-js'
import React, { ReactNode } from 'react'
import { supabase } from '../supabase';


export interface SessionContextValue {
  session: Session | null;
  setSession: React.Dispatch<React.SetStateAction<Session | null>>;
}

export const SessionContext = createContext<SessionContextValue>({
  session: null,
  setSession: () => { },
});

interface SessionProviderProps {
  children: ReactNode;
}


export const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <SessionContext.Provider value={{ session, setSession }}>
      {children}
    </SessionContext.Provider>
  );
};
