import React, { useEffect, useState } from "react";
import { useWrapped } from "../../hooks/useWrapped";
import { SupabaseWrappedObject } from "../../api/wrapped";
import { ViewWrappedCard } from "./WrappedCard";

export const ViewAllWrapped: React.FC = () => {
  const { getAll } = useWrapped();

  const [wrappedObjects, setWrappedObjects] = useState<
    SupabaseWrappedObject[] | null
  >(null);

  useEffect(() => {
    async function fetchWrapped() {
      const data = await getAll();
      if (data) {
        setWrappedObjects(data);
      }
    }
    fetchWrapped();
  }, []);

  return (
    <div className="flex flex-col overflow-scroll pt-10  w-screen bg-gray-100 items-center justify-center">
      {wrappedObjects &&
        wrappedObjects.map((wrappedObject, index) => {
          return (
            <div className="my-1 ">
              {wrappedObject && (
                <ViewWrappedCard
                  showViewAll={false}
                  key={`wrapped-${index}`}
                  wrappedObject={wrappedObject}
                />
              )}{" "}
            </div>
          );
        })}
    </div>
  );
};
