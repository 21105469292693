import { supabase } from "../supabase";
import { Database } from "../../types/supabase";

export async function createStoryForUser(userId: string) {
  const payload = {
    owner_id: userId,
  };
  const { data: story, error } = await supabase.from("stories").insert(payload);
  return null;
}

export async function getStoryForUserId(userId: string) {
  const { data: stories, error } = await supabase
    .from("stories")
    .select("*")
    .eq("owner_id", userId);
  if (stories && stories.length) {
    return stories[0];
  }
  return null;
}

export async function fetchBooksByStoryId(storyId: string) {
  const { data, error } = await supabase.rpc("fetch_books_by_story_id", {
    storyid: storyId,
  });
  if (error) throw error;
  return data!;
}

export async function removeBookFromStory(bookId: string, storyId: string) {
  const { data, error } = await supabase
    .from("story_books")
    .delete()
    .eq("book_id", bookId)
    .eq("story_id", storyId);
}
export async function addBookToStory(bookId: string, storyId: string) {
  const payload = {
    book_id: bookId,
    story_id: storyId,
  };

  const { data, error } = await supabase.from("story_books").insert([payload]);
}

export async function fetchImageUrlFromBookSelection(bookId: string) {
  const { data, error } = await supabase.rpc(
    "fetch_stack_image_url_from_book_id",
    {
      book_id: bookId,
    }
  );
  if (error) throw error;
  return data[0]!;
}
export async function fetchImageUrlandImageIdFromBookSelection(bookId: string) {
  const { data, error } = await supabase.rpc(
    "fetch_stack_image_url_and_id_from_book_id",
    {
      book_id: bookId,
    }
  );
  if (error) throw error;
  return data[0]!;
}
