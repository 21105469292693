import React, { useEffect, useState, FC, useRef, useCallback } from "react";
import { SupabaseMask, saveCroppedMask } from "../../api/masks";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { S3_BUCKET_PREFIX } from "../../constants";
import {
  CropperRef,
  Cropper,
  CropperPreviewRef,
  CropperPreview,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

interface CropModalProps {
  isOpen: boolean;
  onClose: () => void;
  mask: SupabaseMask | null;
  cropMask: (maskId: string, file: File) => void;
}

export const CropModal: FC<CropModalProps> = ({
  isOpen,
  onClose,
  mask,
  cropMask,
}) => {
  const cropperRef = useRef<CropperRef>(null);
  const previewRef = useRef<CropperPreviewRef>(null);
  const prevSliderValueRef = useRef(0);

  const [rotation, setRotation] = useState(0);

  const getCroppedImg = (canvas: HTMLCanvasElement): Promise<File> => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          reject(new Error("Canvas is empty"));
          return;
        }
        const file = new File([blob], "crop.png", { type: "image/jpeg" });
        let fileUrl = window.URL.createObjectURL(file);
        resolve(file);
      }, "image/jpeg");
    });
  };

  const onSave = async (): Promise<void> => {
    if (cropperRef.current) {
      const canvas: HTMLCanvasElement | null = cropperRef.current.getCanvas();
      if (!canvas || !mask) {
        return;
      }
      const coordinates: any = cropperRef.current.getCoordinates(); // Replace 'any' with the actual type
      console.log(coordinates, canvas);

      const file: File = await getCroppedImg(canvas);

      saveCroppedMask(mask.id, file)
        .then((data: any) => console.log(data)) // Replace 'any' with the actual type
        .catch((error: Error) => console.error(error));

      cropMask(mask.id, file);
      onClose();
    }
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      const test = cropperRef.current;
      test.rotateImage(angle);
    }
  };
  const handleSliderChange = (value: number) => {
    prevSliderValueRef.current = rotation;
    setRotation(value);
  };
  useEffect(() => {
    setRotation(0);
  }, [mask]);

  useEffect(() => {
    if (prevSliderValueRef.current > rotation) {
      rotate(-0.3);
    } else {
      rotate(0.3);
    }
  }, [rotation]);

  const onUpdate = () => {
    previewRef.current?.refresh();
  };

  return (
    <div>
      {mask && (
        <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Crop Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Slider
                aria-label="slider-ex-1"
                min={-90}
                max={90}
                step={1}
                value={rotation}
                onChange={handleSliderChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <div className="flex flex-col h-full items-center h-[75vh]">
                <Cropper
                  ref={cropperRef}
                  src={`${S3_BUCKET_PREFIX}/${mask.image_url}`}
                  className={"cropper w-[50vw] h-[30vh]"}
                  onUpdate={onUpdate}
                />
                <div className="flex items-center w-full justify-around">
                  <CropperPreview
                    ref={previewRef}
                    cropper={cropperRef}
                    className="preview w-[25vw] h-[20vh]"
                  />
                  <button
                    onClick={onSave}
                    className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                  >
                    Save Crop
                  </button>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
