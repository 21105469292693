import React, { useState } from "react";
import { Mask } from "../../hooks/useMasks";
import { SupabaseBook } from "../../api/books";
import { GoogleBook } from "../annotation/BookSearch";
import { BookCard } from "../Annotation";

interface BookGridItemProps {
  mask: Mask;
  setSelectedMask: () => void;
  books: SupabaseBook[];
  selectBook: (book: GoogleBook, maskId: string) => void;
}
export const BookGridItem: React.FC<BookGridItemProps> = ({
  mask,
  books,
  setSelectedMask,
  selectBook,
}) => {
  const [showSelection, setShowSelection] = useState(false);
  const bookSelection = books.find((val) => val.mask_id == mask.id);

  const bookGuesses = JSON.parse(
    bookSelection?.prospective_books?.toString() || "[]"
  );

  const showBookSelection =
    bookSelection && (bookSelection.title || bookSelection.author);

  let showGuesses = false;
  if (!showBookSelection && bookGuesses.length > 0) {
    showGuesses = true;
  }
  const bookDisplay = () => {
    if (showBookSelection) {
      return (
        <BookCard
          title={bookSelection.title || ""}
          cover={bookSelection.cover || ""}
          author={bookSelection.author || ""}
          onClick={setSelectedMask}
        />
      );
    }
    if (showSelection) {
      return <div></div>;
    }

    if (showGuesses) {
      return <div></div>;
    }
    return <div></div>;
  };

  return (
    <>
      {showBookSelection && (
        <div className="w-64 h-32  px-2 py-1 flex justify-between">
          <div className="flex-1 flex items-center justify-center">
            <div className="w-full rounded-lg" key={`book-${mask.id}`}>
              {bookDisplay()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
