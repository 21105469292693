import React, { FC, useEffect, useState } from "react";
import { BookSearchModal, GoogleBook } from "../annotation/BookSearch";
import { IoMdArrowBack } from "react-icons/io";
import { BookCard, EmptyBookCard } from "../Annotation";
import Search from "../basics/Search";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { BACKEND_URL } from "../../constants";
import { useWrapped } from "../../hooks/useWrapped";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

type BookOutput = {
  id: string;
  author: string;
  title: string;
  summary: string;
  cover: string;
};

type WrappedModel = {
  summary: string;
  insult_header: string;
  title: string;
};

interface IBookPickModal {
  isOpen: boolean;
  onClose: () => void;
  selectBook: (book: GoogleBook) => void;
}

const BookPickModal: FC<IBookPickModal> = ({ isOpen, onClose, selectBook }) => {
  const [searchBooks, setSearchBooks] = useState<GoogleBook[] | null>(null);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="h-[60vh] w-[40vw] overflow-scroll">
            <div className="w-[30vw]">
              <div className="flex justify-between">
                <div className="flex item-center relative">
                  <div className="font-bold text-lg">Book Search</div>
                </div>
              </div>
              <Search setResults={setSearchBooks} />
              <div className="overflow-scroll">
                {searchBooks &&
                  searchBooks.map((book, index) => {
                    if (book.title || book.author) {
                      return (
                        <div className="w-11/12 m-1" key={`book-${index}`}>
                          <BookCard
                            title={book.title || ""}
                            cover={book.cover || ""}
                            author={book.author || ""}
                            onClick={() => {
                              selectBook(book);
                              onClose();
                            }}
                            key={`book-${index}`}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const CreateWrapped: React.FC = () => {
  const [selectedBooks, setSelectedBooks] = useState<GoogleBook[]>([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<WrappedModel | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { add: addWrapped } = useWrapped();

  const addBook = (book: GoogleBook) => {
    setSelectedBooks([...selectedBooks, book]);
  };

  const postBooks = () => {
    setLoading(true);

    const outputData = selectedBooks.map((val) => {
      return {
        id: val.isbn_10,
        author: val.author,
        title: val.title,
        summary: "",
        cover: val.cover,
      } as BookOutput;
    });
    fetch(`${BACKEND_URL}/wrapped/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(outputData),
    })
      .then((response) => response.json())
      .then((data) => {
        const response = data as WrappedModel;
        setResponse(response);

        const wrappedData = {
          header: response.title,
          subHeader: response.insult_header,
          summary: response.summary,
          books: outputData,
        };
        addWrapped(wrappedData).then((data) => {
          navigate(`/wrapped/${data[0].id}`);
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center">
      <div className="p-4 w-[60vw]">
        {loading ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white p-5 rounded-lg flex items-center space-x-3">
              <Spinner />
              <div>Submitting...</div>
            </div>
          </div>
        ) : null}
        {response ? (
          <div className="flex h-[100vh] w-[100vh] items-center justify-center">
            <div className="flex w-[600px] border bg-gray-100 rounded-lg">
              <div className="flex flex-col">
                <h1 className="text-xl font-bold">{response.title}</h1>
                <h2 className="text-lg italic">{response.insult_header}</h2>
                <p>{response.summary}</p>
              </div>
              <div>
                {selectedBooks &&
                  selectedBooks.map((book, index) => {
                    if (book.title || book.author) {
                      return (
                        <div
                          className="w-full h-[100px] my-1"
                          key={`book-${index}`}
                        >
                          <BookCard
                            key={`book-${index}`}
                            title={book.title || ""}
                            cover={book.cover || ""}
                            author={book.author || ""}
                            // onClick={() => {
                            //   addBook(book);
                            // }}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        ) : null}

        {!response && (
          <div className="flex items-start justify-center ">
            <div>
              <div className="flex flex items-center justify-center">
                <div className="font-bold text-xl">
                  Pick four books to get started
                </div>
                <button
                  onClick={() => navigate(`/wrapped`)}
                  className="ml-2 z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                >
                  View All
                </button>
              </div>
              <div className="mt-2 w-[500px] h-[85vh] rounded-lg p-8 bg-gray-100 flex overflow-scroll flex-col items-center justify-center">
                <div className="flex flex-col h-full items-center w-[400px]">
                  {Array.from({ length: 1 }).map((_, index) => (
                    <EmptyBookCard
                      key="empty Card"
                      onClick={() => {
                        onOpen();
                      }}
                    />
                  ))}

                  {selectedBooks &&
                    selectedBooks.map((book, index) => {
                      if (book.title || book.author) {
                        return (
                          <div
                            className="w-full h-[100px] my-1"
                            key={`book-${index}`}
                          >
                            <BookCard
                              key={`book-${index}`}
                              title={book.title || ""}
                              cover={book.cover || ""}
                              author={book.author || ""}
                              // onClick={() => {
                              //   addBook(book);
                              // }}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
                <button
                  className="bg-green-500 text-white p-2"
                  onClick={postBooks}
                >
                  Post Books
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <BookPickModal isOpen={isOpen} onClose={onClose} selectBook={addBook} />
    </div>
  );
};
