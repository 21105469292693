import { useToast } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const BookErrorComponent: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    toast({
      title: "Error Processing Shelf",
      description: "Please retry",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }, []);

  return (
    <div className="h-[90vh] flex flex-col items-center justify-center bg-[#F3F4F6]">
      <div className="flex flex-col w-[50vw] h-[20vh] p-4 bg-white rounded-lg shadow">
        <div className="text-center">
          <div className="text-xl font-bold">
            We ran into an error processing this shelf!
          </div>
          <div className="">
            We are sorry for the inconvenience, please upload the photo of the
            shelf again
          </div>

          <button
            onClick={() => navigate(`/upload`)}
            className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
          >
            Delete Shelf and Re-Upload
          </button>
        </div>
      </div>
    </div>
  );
};
