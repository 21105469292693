import { supabase } from "../supabase";
import { Database } from "../../types/supabase";

import axios from "axios";
import { BACKEND_URL } from "../constants";

export type SupabaseComment = Database["public"]["Tables"]["comments"]["Row"];

export async function fetchCommentByThreadId(threadId: string) {
  const { data, error } = await supabase
    .from("comments")
    .select("*")
    .eq("threadId", threadId);

  if (error) throw error;
  return data!;
}

export async function addComment(
  userId: string,
  content: string,
  threadId: string,
  bookId: string | null
) {
  const payload = { owner: userId, content, bookId, threadId };
  const { data, error } = await supabase.from("comments").insert([payload]);
}

export async function removeComment(commentId: string) {
  const { data, error } = await supabase
    .from("comments")
    .delete()
    .eq("id", commentId);
}
