import React from "react";
import Page from "./Page";
import { usePagePositions } from "./hooks/usePagePositions";
import { PagesProvider, usePages } from "./context/pageContext";

const PageBrowser: React.FC = () => {
  const { pages, addPage, removePage } = usePages();

  const { mainScrollRef, pageRefs, activePageIndex, scrollToIndex } =
    usePagePositions(pages);

  return (
    <div
      ref={mainScrollRef}
      className="w-screen h-[90.5vh] flex items-center bg-gray-200 overflow-x-scroll "
    >
      {pages.map((page, index) => (
        <Page
          key={page.title}
          pageData={page}
          ref={(el) => (pageRefs.current[index] = el)}
          pageIndex={index}
          activePageIndex={activePageIndex}
          scrollToPage={() => scrollToIndex(index)}
          removePage={removePage}
          addPage={addPage}
        />
      ))}
    </div>
  );
};

export default PageBrowser;
