// Define 'Author' type
export type Author = {
  name: string;
  works: string[];
  contribution: string;
  id: string;
  photo: string;
};

// Initialize authors
export const authors: Author[] = [
  {
    name: "James Joyce",
    works: [
      "Ulysses",
      "A Portrait of the Artist as a Young Man",
      "Finnegans Wake",
    ],
    id: "1",
    contribution:
      "Regarded as one of the most influential and innovative writers of the 20th century, Joyce introduced a style of narration called 'stream of consciousness'.",
    photo:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/James_Joyce_by_Alex_Ehrenzweig%2C_1915_cropped.jpg/800px-James_Joyce_by_Alex_Ehrenzweig%2C_1915_cropped.jpg",
  },
  {
    name: "Marcel Proust",
    works: ["In Search of Lost Time"],
    id: "2",
    contribution:
      "Proust's work provided an immense influence on modern literature by introducing introspective narration that revolved around themes of time and memory.",
    photo:
      "https://i0.wp.com/therealnews.com/wp-content/uploads/2022/11/Otto_Wegener_Proust_vers_1895_bis-resize-e1668796904862.jpeg?fit=1198%2C926&ssl=1",
  },
  {
    id: "3",
    name: "William Faulkner",
    works: ["The Sound and the Fury", "As I Lay Dying", "Light in August"],
    contribution:
      "Faulkner's experimental writing style, with its shifts in time and unconventional narratives, made significant contributions to modernist literature.",
    photo:
      "https://s3.amazonaws.com/loa-production-23ffs35gui41a/writers/images/000/000/301/big/faulkner_william_WD.jpg?1458580559",
  },
  {
    id: "4",
    name: "Virginia Woolf",
    works: ["To the Lighthouse", "Mrs. Dalloway", "Orlando"],
    contribution:
      "Woolf was a pioneer of modernist literature whose stream-of-consciousness prose style reshaped the literary landscape.",
    photo: "https://news.artnet.com/app/news-upload/2018/02/vw.jpg",
  },
  {
    id: "5",
    name: "Jorge Luis Borges",
    works: ["Ficciones", "El Aleph"],
    contribution:
      "Borges' works, which blurred the line between genres, are key texts in magical realism and postmodern literature.",
    photo:
      "https://www.creativityatwork.com/wp-content/uploads/jorge-luis-borges.jpg",
  },
  {
    id: "6",
    name: "Franz Kafka",
    works: ["The Metamorphosis", "The Trial", "The Castle"],
    contribution:
      "Kafka's works, characterized by their surreal, nightmarish qualities, explore complex themes like alienation, guilt, and absurdity.",
    photo:
      "https://wordpress.wbur.org/wp-content/uploads/2016/11/1123_kafka-1000x735.jpg",
  },
  {
    id: "7",
    name: "Thomas Pynchon",
    works: ["Gravity's Rainbow", "The Crying of Lot 49", "Inherent Vice"],
    contribution:
      "Pynchon is a seminal figure in postmodern literature, known for his complex narratives and exploration of paranoia and entropy.",
    photo:
      "https://upload.wikimedia.org/wikipedia/commons/c/ca/Thomas_Pynchon%2C_high_school_yearbook_editor%2C_1953.jpg",
  },
  {
    id: "8",
    name: "David Foster Wallace",
    works: [
      "Infinite Jest",
      "The Pale King",
      "Brief Interviews with Hideous Men",
    ],
    contribution:
      "Wallace's maximalist approach to literature and his detailed, often sprawling narratives helped to define a new direction for postmodern literature.",
    photo:
      "https://www.rollingstone.com/wp-content/uploads/2018/06/rs-19794-rectangle.jpg?w=624",
  },
  {
    id: "9",
    name: "Doris Lessing",
    works: ["The Golden Notebook", "The Grass is Singing", "Shikasta"],
    contribution:
      "Lessing's works tackled issues like post-colonialism, feminism, and psychological realism, making her one of the key figures in modern British literature.",
    photo:
      "https://i.guim.co.uk/img/static/sys-images/Guardian/Pix/pictures/2013/11/17/1384691988398/Doris-Lessing-in-1962.-009.jpg?width=465&dpr=1&s=none",
  },
  // ... add 20 more authors in the same pattern
];

// Function to find author by id
export function findAuthorById(id: string): Author | null {
  const author = authors.find((author) => author.id === id);
  return author || null;
}
