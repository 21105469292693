import { supabase } from "../supabase";
import { Database } from "../../types/supabase";

export type SupabaseBook =
  Database["public"]["Tables"]["book_selection"]["Row"];

export async function fetchBooksByUserId(userId: string) {
  const { data, error } = await supabase.rpc("fetch_books_by_user_id", {
    userid: userId,
  });
  if (error) throw error;
  return data!;
}

export async function fetchBooksByImageId(imageId: string) {
  const { data, error } = await supabase
    .from("book_selection")
    .select("*, masks!inner(*)")
    .eq("masks.parent_image_id", imageId);

  if (error) throw error;
  return data!;
}

export async function fetchBooksByMaskId(maskId: string) {
  const { data, error } = await supabase
    .from("book_selection")
    .select("*")
    .eq("mask_id", maskId);

  if (error) throw error;
  return data!;
}

export async function searchBooksByImageId(imageId: string) {
  // get all books for imageId
  const { data, error } = await supabase.rpc(
    "fetch_books_by_imageid_and_search",
    { imageid: imageId, searchquery: "Year" }
  );
  if (error) throw error;
  return data!;
}
