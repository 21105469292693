import React, { useEffect, useState } from "react";
import { Mask } from "../../hooks/useMasks";
import { SupabaseBook } from "../../api/books";
import { GoogleBook } from "../annotation/BookSearch";
import { BigBookCover, BookCard } from "../Annotation";
import { faker } from "@faker-js/faker";
import ProfilePhoto from "./basics/ProfilePhoto";

interface BookGridItemProps {
  mask: Mask;
  books: SupabaseBook[];
}
export const BookDisplay: React.FC<BookGridItemProps> = ({ mask, books }) => {
  const [showSelection, setShowSelection] = useState(false);
  const bookSelection = books.find((val) => val.mask_id == mask.id);

  const [fakeWords, setFakeWords] = useState<string[]>([]);

  const bookGuesses = JSON.parse(
    bookSelection?.prospective_books?.toString() || "[]"
  );

  const showBookSelection =
    bookSelection && (bookSelection.title || bookSelection.author);

  let showGuesses = false;
  if (!showBookSelection && bookGuesses.length > 0) {
    showGuesses = true;
  }

  useEffect(() => {
    const words = [
      faker.word.adjective(),
      faker.word.adjective(),
      faker.word.adjective(),
    ];
    setFakeWords(words);
  }, []);

  return (
    <>
      {showBookSelection && (
        <>
          <div className="flex w-full h-[270px] ">
            <div className="flex flex-col w-1/3 h-full items-center justify-center">
              <div
                className="flex items-center justify-center rounded-lg"
                key={`book-${mask.id}`}
              >
                <BigBookCover
                  title={bookSelection.title || ""}
                  cover={bookSelection.cover || ""}
                  author={bookSelection.author || ""}
                />
              </div>
              <button
                onClick={() => {}}
                className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded text-center font-medium"
              >
                Add to my list
              </button>
            </div>
            <div className="flex w-3/4 h-full overflow-scroll pr-2">
              <div className="flex items-center justify-start ">
                <div className="flex flex-col text-wrap">
                  <div className="text-2xl font-bold ">
                    {bookSelection.title}
                  </div>
                  <div className="italic text-lg mt-1">
                    {bookSelection.author}
                  </div>
                  <div className="text-ellipses max-h-[100px] overflow-scroll">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Volutpat commodo sed egestas egestas fringilla
                    phasellus. Diam quis enim lobortis scelerisque fermentum dui
                    faucibus in. Aliquam id diam maecenas ultricies mi eget
                    mauris pharetra et. Augue do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Volutpat commodo sed egestas
                    egestas fringilla phasellus. Diam quis enim lobortis
                    scelerisque fermentum dui faucibus in. Aliquam id diam
                    maecenas ultricies mi eget mauris pharetra et.
                  </div>
                  <div className="mt-6 flex items-center overflow-x-hidden ">
                    <span className="text-gray-500 mr-3">Genres: </span>
                    <div className="flex">
                      {fakeWords &&
                        fakeWords.map((word, i) => {
                          return (
                            <div
                              key={i}
                              className="py-1 px-3 mx-1 rounded-lg bg-gray-100"
                            >
                              {word}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full items-center justify-center flex px-5 border-b pb-4">
            <div className="flex items-center ">
              <ProfilePhoto
                imageUrl={
                  "https://pbs.twimg.com/profile_images/1506132892916744197/BGgZxSL3_400x400.jpg"
                }
              />
            </div>
            <div className="ml-2 text-lg text-start italic text-gray-600">
              "One of my favorites of all time!"
            </div>
          </div>
        </>
      )}
    </>
  );
};
