import React, { useEffect, useRef, useState } from "react";
import { Stage, Layer, Image, Transformer } from "react-konva";
import Konva from "konva";
import useImage from "use-image";
import { v4 as uuidv4 } from "uuid";
import { S3_BUCKET_PREFIX } from "../../constants";
import { useDisclosure } from "@chakra-ui/react";
import { CropModal } from "./CropModal";
import { Mask, useMasks } from "../../hooks/useMasks";
import { useParams } from "react-router-dom";

const SHELF_HEIGHT_PX = 500;
const SHELF_WIDTH_PX = window.innerWidth - SHELF_HEIGHT_PX - 50;

const plantUrls = [
  "bookshelves/plant/big-cactus.png",
  "bookshelves/plant/big-guy.png",
  "bookshelves/plant/small.png",
  "bookshelves/plant/medium.png",
  "bookshelves/plant/medium-2.png",
  "bookshelves/plant/cactus.png",
];

const sheffUrls = [
  "bookshelves/cartoon-shelf/image+101.png",
  "bookshelves/cartoon-shelf/image+102.png",
  "bookshelves/cartoon-shelf/image+103.png",
  "bookshelves/cartoon-shelf/shelf.png",
];

enum Tab {
  Books = "books",
  Plants = "plants",
  Templates = "templates",
}

interface ImageData {
  id: string;
  x: number;
  y: number;
  src: string;
  maskId: string;
}

const shelfBaseImage =
  "https://bookstack-store.s3.amazonaws.com/bookshelves/Skeumorphic+Mix.png";

interface ShelfBaseImageProps {
  imageUrl: string;
}
const ShelfBaseImage: React.FC<ShelfBaseImageProps> = ({ imageUrl }) => {
  const [img] = useImage(`${S3_BUCKET_PREFIX}/${imageUrl}`, "anonymous");
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (img) {
      let width = img?.width;
      let height = img?.height;

      let maxDimensionSize = 700;

      const aspectRatio = width / height;

      if (width > height) {
        if (width > maxDimensionSize) {
          width = maxDimensionSize;
          height = width / aspectRatio;
        }
      } else {
        if (height > maxDimensionSize) {
          height = maxDimensionSize;
          width = height * aspectRatio;
        }
      }
      setImageSize({ width, height });
    }
  }, [img]);

  return (
    <Image
      height={imageSize.height}
      width={imageSize.width}
      image={img}
      offsetY={imageSize.height - SHELF_HEIGHT_PX + 30}
      offsetX={-(SHELF_WIDTH_PX - imageSize.width) / 2}
    />
  );
};

type URLImageProps = {
  image: ImageData;
  isSelected: boolean;
  onSelect: (t: any) => void;
  onChange: (t: any) => void;
};

const URLImage: React.FC<URLImageProps> = ({
  image,
  isSelected,
  onSelect,
  onChange,
}) => {
  const [img] = useImage(image.src, "anonymous");
  const imageRef = useRef<Konva.Image>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (img) {
      let width = img?.width;
      let height = img?.height;

      let maxDimensionSize = 300;
      if (image.src.includes("plant")) {
        maxDimensionSize = 220;
      }

      const aspectRatio = width / height;

      if (width > height) {
        if (width > maxDimensionSize) {
          width = maxDimensionSize;
          height = width / aspectRatio;
        }
      } else {
        if (height > maxDimensionSize) {
          height = maxDimensionSize;
          width = height * aspectRatio;
        }
      }
      setImageSize({ width, height });
    }
  }, [img]);

  React.useEffect(() => {
    if (isSelected) {
      console.log("selected");
      if (trRef.current && imageRef.current) {
        trRef.current.nodes([imageRef.current]);
        const layer = trRef.current?.getLayer();
        if (layer) {
          layer.batchDraw();
        }
      }
    }
  }, [isSelected]);

  return (
    <>
      <Image
        cornerRadius={3}
        ref={imageRef}
        draggable
        image={img}
        attrs={{ src: image.src }}
        width={imageSize.width}
        height={imageSize.height}
        x={image.x}
        y={image.y}
        offsetX={imageSize.width / 2}
        offsetY={imageSize.height / 2}
        onClick={onSelect}
      />
      {isSelected && <Transformer ref={trRef} />}
    </>
  );
};

interface ShelfRemixChildProps {
  imageId: string;
}

const ShelfRemixChild: React.FC<ShelfRemixChildProps> = ({ imageId }) => {
  const dragUrl = React.useRef("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const [images, setImages] = React.useState<ImageData[]>([]);

  const { masks, cropMask } = useMasks(imageId || "");

  const [stage, setStage] = useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const [activeTab, setActiveTab] = useState(Tab.Templates);

  const [selectedId, setSelectedId] = React.useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(sheffUrls[3]);

  const checkDeselect = (e: { target: { getStage: () => any } }) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId("");
    }
  };
  const handleWheel = (e: {
    evt: { preventDefault: () => void; deltaY: number };
    target: { getStage: () => any };
  }) => {
    e.evt.preventDefault();

    const scaleBy = 1.02;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStage({
      scale: newScale,
      x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
      y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale,
    });
  };

  const saveImage = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });

      const link = document.createElement("a");
      link.download = "canvas.png";
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const saveState = () => {
    if (stageRef.current) {
      const stageState = {
        stageScale: stageRef.current.scale(),
        stageState: stageRef.current.toJSON(),
      };
      localStorage.setItem("stageState", JSON.stringify(stageState));
    }
  };

  const loadState = () => {
    const stageStateStr = localStorage.getItem("stageState");
    if (stageStateStr) {
      const parsed = JSON.parse(stageStateStr);
      const stageState = JSON.parse(parsed.stageState);
      console.log(stageState);
    }
  };
  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        setImages((prev) => {
          return prev.filter((img) => img.id !== selectedId);
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedId, setImages]);

  return (
    <div className="flex items-start justify-center h-[90vh]">
      <div className="flex flex-col w-[400px] justify-start mr-10 mt-10 border h-[80vh]">
        <div className="flex justify-around mb-1">
          <button
            className=" mt-2 px-2 py-1 border rounded"
            onClick={() => setActiveTab(Tab.Books)}
          >
            Books
          </button>
          <button
            className=" mt-2 px-2 py-1 border rounded"
            onClick={() => setActiveTab(Tab.Plants)}
          >
            Plants
          </button>
          <button
            className=" mt-2 px-2 py-1 border rounded"
            onClick={() => setActiveTab(Tab.Templates)}
          >
            Templates
          </button>
        </div>
        {activeTab === Tab.Templates && (
          <div className="flex flex-col items-center overflow-scroll ">
            {sheffUrls.map((url, index) => (
              <img
                onClick={() => {
                  setSelectedTemplate(url);
                }}
                key={index}
                src={`${S3_BUCKET_PREFIX}/${url}` || ""}
                alt="Book"
                className="cursor-pointer px-2 max-h-[10vw] object-contain rounded-lg mt-4"
              />
            ))}
          </div>
        )}

        {activeTab === Tab.Books && (
          <div className="flex flex-col items-center w-[400px] overflow-scroll ">
            {masks &&
              masks.map((mask, index) => {
                const maskImage = mask.maskImage
                  ? URL.createObjectURL(mask.maskImage)
                  : `${S3_BUCKET_PREFIX}/${
                      mask.crop_image_url ? mask.crop_image_url : mask.image_url
                    }` || "";
                return (
                  <div className="flex">
                    <img
                      onDragStart={(e) => {
                        const target = e.target as HTMLImageElement;
                        dragUrl.current = target.src;
                      }}
                      key={index}
                      draggable="true"
                      src={maskImage}
                      alt="Book"
                      className="px-2 w-[30vw] max-h-[10vw] object-contain rounded-lg mt-4"
                    />
                    <button
                      className="rounded border px-2 py-1 w-40 h-3/4"
                      onClick={() => {
                        setSelectedMask(mask);
                        onOpen();
                      }}
                    >
                      crop
                    </button>
                  </div>
                );
              })}
          </div>
        )}
        {activeTab === Tab.Plants && (
          <div className="flex flex-col items-center overflow-scroll ">
            {plantUrls.map((url, index) => (
              <img
                onDragStart={(e) => {
                  const target = e.target as HTMLImageElement;
                  dragUrl.current = target.src;
                }}
                key={index}
                draggable="true"
                src={`${S3_BUCKET_PREFIX}/${url}` || ""}
                alt="Book"
                className="px-2 max-h-[10vw] object-contain rounded-lg mt-4"
              />
            ))}
          </div>
        )}

        <div className="flex justify-center p-2 text-sm">
          <button className="border rounded p-2 m-1 w-1/4" onClick={saveImage}>
            Save Image
          </button>
          <button className="border rounded p-2 m-1 w-1/4" onClick={saveState}>
            Save State
          </button>
          <button className="border rounded p-2 m-1 w-1/4" onClick={loadState}>
            Load State
          </button>
        </div>
      </div>
      <div
        className="mr-[25px] self-center"
        onDrop={(e) => {
          e.preventDefault();
          // register event position
          if (stageRef.current) {
            stageRef.current.setPointersPositions(e);
            if (dragUrl.current.includes("template")) {
              setActiveTab(Tab.Books);
            }
            setImages((prev) => {
              const vals = stageRef.current?.getPointerPosition();
              const newVal = {
                id: uuidv4(),
                x: vals?.x || 0,
                y: vals?.y || 0,
                src: dragUrl.current || "",
              } as ImageData;
              if (prev) {
                return [...prev, newVal];
              } else {
                return [newVal];
              }
            });
          }
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <Stage
          draggable
          className="border-2"
          width={SHELF_WIDTH_PX}
          height={SHELF_HEIGHT_PX}
          onWheel={handleWheel}
          scaleX={stage.scale}
          scaleY={stage.scale}
          x={stage.x}
          y={stage.y}
          ref={stageRef}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          onClick={checkDeselect}
        >
          <Layer>
            <div
              onClick={() => {
                setSelectedId("");
              }}
            >
              <ShelfBaseImage imageUrl={selectedTemplate} />
            </div>

            {images.map((image, index) => {
              return (
                <URLImage
                  isSelected={image.id === selectedId}
                  key={`image-${image.id}`}
                  image={image}
                  onSelect={() => {
                    setSelectedId(image.id);
                  }}
                  onChange={() => {}}
                />
              );
            })}
          </Layer>
        </Stage>
        <CropModal
          isOpen={isOpen}
          onClose={onClose}
          mask={selectedMask}
          cropMask={cropMask}
        />
      </div>
    </div>
  );
};

export function ShelfRemix() {
  const { imageId } = useParams();
  return <ShelfRemixChild imageId={imageId || ""} key={imageId} />;
}
