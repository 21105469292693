import React, { useEffect, useState } from "react";
import { useSession } from "../../context/hooks/useSession";
import { SupabaseBook } from "../../api/books";
import { useMasks, Mask } from "../../hooks/useMasks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useImageData } from "../../hooks/useImage";
import { Spinner, useDisclosure } from "@chakra-ui/react";

import { useBooks } from "../../hooks/useBooks";
import { S3_BUCKET_PREFIX } from "../../constants";
import { IoMdArrowBack } from "react-icons/io";
import { generateCSV } from "../../utils/bookDownload";
import {
  BigBookCover,
  BookCard,
  BookCardConfirm,
  EmptyBookCard,
} from "../Annotation";
import { SupabaseMask } from "../../api/masks";

import { Button, Radio, RadioGroup, useToast } from "@chakra-ui/react";

import { BiTrash } from "react-icons/bi";

import { BookSearchModal, GoogleBook } from "./BookSearch";
import Search from "../basics/Search";
import { LoadingBooksSVG } from "../ProcessShelf";

enum RemovalReasons {
  IMAGE_NOT_LOAD = "Image did not load",
  IMAGE_NOT_BOOK = "Not a book",
  PART_OF_BOOK = "Partial book",
  DUPLICATE_IMAGE = "Duplicate Book",
  CANNOT_READ = "Can't read the text",
  OTHER_IMAGE = "Other",
}
interface AnnotationCardProps {
  mask: Mask;
  nextMask: () => void;
  books: SupabaseBook[];
  selectBook: (book: GoogleBook, maskId: string) => void;
  removeMask: (maskId: string) => void;
}
export const AnnotationCard: React.FC<AnnotationCardProps> = ({
  mask,
  books,
  nextMask,
  selectBook,
  removeMask,
}) => {
  const [searchBooks, setSearchBooks] = useState<GoogleBook[] | null>(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showRemoveScreen, setShowRemoveScreen] = useState(false);

  const [removeSurveyValue, setRemoveSurveyValue] = useState("");

  const handleRemoveSurveyRadioButtonChange = (val: RemovalReasons) =>
    setRemoveSurveyValue(val);

  const bookSelection = books.find((val) => val.mask_id == mask.id);

  const bookGuesses = JSON.parse(
    bookSelection?.prospective_books?.toString() || "[]"
  );

  const showBookSelection =
    bookSelection && (bookSelection.title || bookSelection.author);

  let showGuesses = false;
  if (!showBookSelection && bookGuesses.length > 0) {
    showGuesses = true;
  }
  const displayBook = showBookSelection
    ? bookSelection
    : bookGuesses[0] || null;

  return (
    <>
      <div className="w-full px-2 py-1 overflow-scroll flex flex-col justify-between h-[70vh] items-center">
        <div className="rounded-lg flex flex-grow items-center h-[20vh]">
          <img
            src={`${S3_BUCKET_PREFIX}/${mask.image_url}`}
            className="max-w-[500px] max-h-[20vh] object-contain rounded "
          />
          {!showRemoveScreen && (
            <button
              onClick={() => {
                removeMask(mask.id);
                setShowRemoveScreen(true);
              }}
              className="ml-2 mb-1 cursor-pointer"
            >
              <BiTrash size={24} className="hover:fill-red-400" />
            </button>
          )}
        </div>
        {showRemoveScreen && (
          <div className="w-full flex items-center justify-center h-[30vh]">
            <div className="flex flex-col items-center just">
              <p>Why did you remove this book?</p>
              <RadioGroup
                className="flex flex-col"
                onChange={handleRemoveSurveyRadioButtonChange}
                value={removeSurveyValue}
              >
                <Radio value={RemovalReasons.IMAGE_NOT_LOAD}>
                  {RemovalReasons.IMAGE_NOT_LOAD}
                </Radio>
                <Radio value={RemovalReasons.IMAGE_NOT_BOOK}>
                  {RemovalReasons.IMAGE_NOT_BOOK}
                </Radio>
                <Radio value={RemovalReasons.PART_OF_BOOK}>
                  {RemovalReasons.PART_OF_BOOK}
                </Radio>
                <Radio value={RemovalReasons.DUPLICATE_IMAGE}>
                  {RemovalReasons.DUPLICATE_IMAGE}
                </Radio>
                <Radio value={RemovalReasons.CANNOT_READ}>
                  {RemovalReasons.CANNOT_READ}
                </Radio>
                <Radio value={RemovalReasons.OTHER_IMAGE}>
                  {RemovalReasons.OTHER_IMAGE}
                </Radio>
              </RadioGroup>
              <Button
                mt={4}
                colorScheme="blue"
                onClick={() => {
                  console.log(removeSurveyValue);
                  nextMask();
                }}
              >
                Next Book
              </Button>
            </div>
          </div>
        )}
        {!showSearchBar && displayBook && (
          <div className="flex w-full justify-center items-center h-full">
            <div className="w-1/2 flex flex-col items-center justify-center ">
              <div className="flex flex-col items-center justify-center w-full  p-2">
                <BigBookCover
                  title={displayBook.title}
                  author={displayBook.author}
                  cover={displayBook.cover}
                />
                <div className="mt-1">
                  <div className="text-center font-bold text-lg line-clamp-1">
                    {displayBook.title}
                  </div>
                  <div className="text-center text-md">
                    {displayBook.author}
                  </div>
                </div>
              </div>
              {showBookSelection && (
                <button
                  className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                  onClick={() => {
                    setShowSearchBar(true);
                  }}
                >
                  Edit Book Selection
                </button>
              )}
            </div>

            <div className="mb-4 w-1/2 flex flex-col items-center justify-center">
              {!showBookSelection && (
                <div className="flex flex-col">
                  <button
                    className="z-10 p-2.5 my-4 bg-[#93B9A0] text-white border hover:bg-[#487535] rounded-sm text-center font-medium"
                    onClick={() => {
                      selectBook(bookGuesses[0] as GoogleBook, mask.id);
                    }}
                  >
                    Confirm Book
                  </button>
                  <button
                    className="z-10 p-2.5 my-4 bg-[#FF7575] text-white border hover:bg-[#CB3333] rounded-sm text-center font-medium"
                    onClick={() => {
                      setShowSearchBar(true);
                    }}
                  >
                    Incorrect Book
                  </button>
                </div>
              )}
              {showBookSelection && (
                <div className="flex flex-col">
                  <button
                    onClick={() => nextMask()}
                    className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                  >
                    Process Next Book
                  </button>

                  <button className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium">
                    View Shelf
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {(!displayBook || showSearchBar) && (
          <div className="w-[40vw] h-[54vh]">
            <div className="flex justify-between">
              <div className="flex item-center relative">
                <button
                  className="absolute -left-6 bottom-1 "
                  onClick={() => {
                    setShowSearchBar(false);
                  }}
                >
                  <IoMdArrowBack
                    size={18}
                    className="fill-gray-400 hover:fill-gray-600"
                  />
                </button>
                <div className="font-bold text-lg">Search for Book</div>
              </div>
            </div>
            <Search setResults={setSearchBooks} />
            <div className="overflow-scroll h-[40vh]">
              {searchBooks &&
                searchBooks.map((book, index) => {
                  if (book.title || book.author) {
                    return (
                      <div className="w-11/12" key={`book-${index}`}>
                        <BookCard
                          title={book.title || ""}
                          cover={book.cover || ""}
                          author={book.author || ""}
                          onClick={() => {
                            selectBook(book, mask.id);
                            setShowSearchBar(false);
                          }}
                          key={`book-${index}`}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface StackAnnotationChildProps {
  imageId: string;
}

const StackAnnotationChild: React.FC<StackAnnotationChildProps> = ({
  imageId,
}) => {
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);
  const [unprocessedMasks, setUnprocessedMasks] = useState<Mask[] | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { session } = useSession();
  const userId = session?.user.id || "";
  const {
    masks,
    removeMask,
    persistMask,
    filterMasks,
    rotateMaskImage,
    searchResultMasks,
    reloadMasks,
  } = useMasks(imageId || null);
  const { image } = useImageData(imageId || "");
  const { books, addBookForMaskId } = useBooks(imageId || "");
  const toast = useToast();

  useEffect(() => {
    const unprocessedMasks = masks?.filter((mask) => {
      const foundBook = books?.find((book) => book.mask_id === mask.id);
      if (!foundBook) {
        return true;
      } else if (!foundBook.title || !foundBook.author) {
        return true;
      } else {
        return false;
      }
    });
    setUnprocessedMasks(unprocessedMasks || null);
  }, [masks, books]);

  const location = useLocation();
  const [fromVerifyStep, setFromVerifyStep] = useState(false);

  useEffect(() => {
    // The URLSearchParams interface defines utility methods to work with the query string of a URL
    const params = new URLSearchParams(location.search);
    const fromVerifyStepParam = params.get("from-verify-step") === "true";
    setFromVerifyStep(fromVerifyStepParam);
  }, [location]); // re-run the effect when the location changes

  const selectUnprocessedMask = () => {
    if (unprocessedMasks && unprocessedMasks?.length > 0) {
      console.log(unprocessedMasks);
      setSelectedMask(unprocessedMasks[0]);
    } else {
      toast({
        title: "Shelf Complete",
        description: "You have finished annotating this shelf",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setSelectedMask(null);
    }
  };

  const backgroundImageUrl = `${S3_BUCKET_PREFIX}/${image?.url}`;

  return (
    <div className="w-[100vw] h-[88vh] overflow-y-auto bg-[#F9F9FA]">
      <div className="flex flex-col">
        <div className="flex items-center justify-center">
          <div className="w-11/12 h-full mt-3 flex justify-between items-center rounded ">
            <div className="flex flex-col items-center w-1/3 h-[80vh] border rounded shadow bg-white ">
              <div className="w-full flex justify-between items-center p-2">
                <div className="font-bold text-lg font-lora">Books</div>
                <div>
                  <div className="text-sm">
                    {unprocessedMasks && unprocessedMasks.length} Books Left to
                    Process
                  </div>
                </div>
              </div>
              <div className="h-[70vh] overflow-scroll">
                {masks &&
                  masks.map((mask, index) => {
                    const maskImage = mask.maskImage
                      ? URL.createObjectURL(mask.maskImage)
                      : `${S3_BUCKET_PREFIX}/${
                          mask.crop_image_url
                            ? mask.crop_image_url
                            : mask.image_url
                        }` || "";
                    return (
                      <div className="flex">
                        <img
                          onClick={() => {
                            setSelectedMask(mask);
                          }}
                          key={index}
                          draggable="true"
                          src={maskImage}
                          alt="Book"
                          className="px-2 w-[30vw] max-h-[10vw] object-contain rounded-lg mt-4 cursor-pointer"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="w-3/5 h-[80vh] rounded border shadow-lg bg-white">
              <div className="flex items-center justify-center">
                {selectedMask ? (
                  <AnnotationCard
                    key={`list-${selectedMask.id}`}
                    nextMask={selectUnprocessedMask}
                    mask={selectedMask}
                    books={books || []}
                    selectBook={addBookForMaskId}
                    removeMask={removeMask}
                  />
                ) : (
                  <div className="flex items-center flex-col justify-between w-full h-[80vh] bg-white">
                    <div className="p-4">
                      <div className="font-bold text-lg">
                        Annotation Instructions
                      </div>

                      {!fromVerifyStep && (
                        <>
                          <div>
                            We've proccessed your shelf, but let's make sure we
                            didn't miss any books. We'll now take you to your
                            shelf, where you will get the chance to click
                            through books we didn't see.
                          </div>
                          <div className="w-full flex items-center justify-center">
                            <div className="flex items-center justify-between w-3/5 ">
                              <button
                                onClick={() =>
                                  navigate(`/full-page-selection/${image?.id}`)
                                }
                                className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                              >
                                Verify Shelf
                              </button>
                              or
                              <button
                                onClick={() => {
                                  selectUnprocessedMask();
                                }}
                                className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                              >
                                Start Annotating
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {fromVerifyStep && (
                        <>
                          <div>
                            Thanks for confirming those books! Now, we will
                            begin the annotation process. At any point, you can
                            see the results by clicking the View Shelf button
                          </div>
                          <div className="w-full flex items-center justify-center">
                            <div className="flex items-center justify-center w-full ">
                              <button
                                onClick={() => {
                                  selectUnprocessedMask();
                                }}
                                className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                              >
                                Start Annotating
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="relative w-full h-[50vh] ">
                      <img
                        src={backgroundImageUrl}
                        alt="photo of current bookshelf"
                        className="absolute h-full w-full object-cover"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <BookSearchModal
          isOpen={isOpen}
          onClose={onClose}
          mask={selectedMask}
          selectBook={addBookForMaskId}
        />
      </div>
    </div>
  );
};

function StackAnnotation() {
  const { imageId } = useParams();
  return <StackAnnotationChild imageId={imageId || ""} key={imageId} />;
}

export default StackAnnotation;
