import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { Mask } from "../../hooks/useMasks";
import { generateCSV } from "../../utils/bookDownload";
import { GoogleBook } from "../annotation/BookSearch";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { SupabaseBook } from "../../api/books";
import { Tab } from "./ViewBooks";

interface Props {
  currentTab: Tab;
  masks: Mask[] | null;
  books: SupabaseBook[] | null;
  setTab: (t: Tab) => void;
}

export const TabView: React.FC<Props> = ({
  currentTab,
  masks,
  books,
  setTab,
}) => {
  const tabs = [Tab.VIEW, Tab.ANNOTATE, Tab.FULL_PAGE];
  return (
    <div className="p-4">
      <div className="border-b border-gray-200 relative">
        <div className="sm:flex sm:items-baseline">
          <div className="mt-4 sm:ml-6 sm:mt-0">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab, index) => (
                <a
                  key={index}
                  className={cx(
                    currentTab === tab
                      ? "border-black text-black"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 font-medium cursor-pointer"
                  )}
                  onClick={() => setTab(tab)}
                >
                  {tab}
                </a>
              ))}
            </nav>
          </div>
          <div className="absolute right-0 bottom-2">
            <div className="flex items-center justify-center ">
              <div className="w-4/5 flex items-center justify-between m-2">
                <div className="w-full ">
                  <div className="m-1 w-full ">
                    {/* <SearchInput onTextChange={handleTextChange} /> */}
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() => generateCSV({ masks, books })}
              className="z-10 p-2 bg-gray-200 rounded-full"
            >
              <AiOutlineDownload size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabView;
