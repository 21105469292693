import { supabase } from "../supabase";
import { Database } from "../../types/supabase";

export type SupabaseQuestionAndAnswer =
  Database["public"]["Tables"]["questions_and_answers"]["Row"];

export async function getQandAsForStoryId(storyId: string) {
  const { data, error } = await supabase
    .from("questions_and_answers")
    .select("*")
    .eq("story_id", storyId);
  return data;
}

export async function addOrUpdateQuestionAndAnswer(
  question: string,
  answer: string,
  storyId: string
) {
  const payload = {
    story_id: storyId,
    question,
    answer,
  };

  const { data: existingQuestion, error: existingError } = await supabase
    .from("questions_and_answers")
    .select("*")
    .eq("story_id", storyId)
    .eq("question", question);

  if (existingQuestion && existingQuestion.length > 0) {
    const { data, error } = await supabase
      .from("questions_and_answers")
      .update({ answer: answer })
      .eq("id", existingQuestion[0].id);
  } else {
    const { data, error } = await supabase
      .from("questions_and_answers")
      .insert([payload]);
  }
}
