import { useState, useEffect } from "react";
import {
  SupabaseWrappedObject,
  WriteWrappedObject,
  addWrapped,
  deleteWrapped,
  getAllWrapped,
  getWrappedById,
} from "../api/wrapped";

export const useWrapped = () => {
  const [wrappedData, setWrappedData] = useState<
    SupabaseWrappedObject[] | null
  >(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await getAllWrapped();
  //     setWrappedData(data);
  //     setLoading(false);
  //   };
  //   fetchData();
  // }, []);
  const getAll = async () => {
    return await getAllWrapped();
  };

  const get = async (wrappedId: string) => {
    return await getWrappedById(wrappedId);
  };

  const add = async (wrappedData: WriteWrappedObject) => {
    const newData = await addWrapped(wrappedData);
    return newData;
    // setWrappedData((prevData) => [...prevData, ...newData]);
  };

  const remove = async (id: string) => {
    await deleteWrapped(id);
    // setWrappedData((prevData) => prevData.filter((item) => item.id !== id));
  };

  return { wrappedData, loading, get, getAll, add, remove };
};
