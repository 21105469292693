import React, { useContext, useState, FC, useEffect } from "react";
import { Mask } from "../hooks/useMasks";
import { NUMBER_OF_BOOKS_TO_SELECT, S3_BUCKET_PREFIX } from "../constants";
import { Button, Switch } from "@chakra-ui/react";
import { AiOutlineCloseCircle, AiOutlineRotateRight } from "react-icons/ai";
import { BookCard } from "./Annotation";
import { fetchBooksByMaskId } from "../api/books";
import { AnimatePresence, motion } from "framer-motion";

import SearchInput from "./SearchInput";

const imageVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1.0,
    y: 0,
    transition: {
      // these seem to have no effect..
      ease: "easeIn",
      duration: 4.8,
      delay: 1.1,
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};

interface IBookDisplay {
  mask: Mask;
  selectMask: (maskId: string) => void;
}

const BookDisplay: FC<IBookDisplay> = ({ mask, selectMask }) => {
  const [book, setBook] = useState<any | null>(null);
  async function getBooks() {
    const books = await fetchBooksByMaskId(mask.id);
    if (books) {
      const book = books[0];
      setBook(book);
    }
  }
  useEffect(() => {
    getBooks();
  }, [mask]);

  return (
    <div className="w-[26vw]" onClick={() => selectMask(mask.id)}>
      {book && book.title && book.author && (
        <BookCard title={book.title} author={book.author} cover={book.cover} />
      )}
    </div>
  );
};

interface IImageWithButton {
  mask: Mask;
  removeMask: (maskId: string) => void;
  rotateMaskImage: (maskId: string, flipMask: boolean) => void;
  selectMask: (maskId: string) => void;
  showButtons: boolean;
}

export const ImageWithButton: FC<IImageWithButton> = ({
  mask,
  removeMask,
  rotateMaskImage,
  selectMask,
  showButtons = true,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleClose = (maskId: string) => {
    removeMask(maskId);
  };

  const handleRotateimage = (maskId: string) => {
    let shouldFlip = false;
    if (!mask.should_flip_mask) {
      shouldFlip = true;
    }
    rotateMaskImage(maskId, shouldFlip);
  };
  return (
    <motion.div
      key={mask.id}
      variants={imageVariants}
      animate="visible"
      exit="exit"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex items-center justify-center w-[57vw]"
      layout
    >
      {showButtons && (
        <div
          className={`cursor-pointer mr-3 transition-opacity duration-300 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        >
          <AiOutlineCloseCircle
            onClick={() => {
              handleClose(mask.id);
            }}
          />
        </div>
      )}
      <img
        src={
          mask.maskImage
            ? URL.createObjectURL(mask.maskImage)
            : `${S3_BUCKET_PREFIX}/${mask.image_url}` || ""
        }
        alt="Book spine"
        className={`w-[80%] h-auto ${mask.should_flip_mask && "rotate-180"} ${
          showButtons && "rounded-lg"
        } cursor-pointer`}
        onClick={() => selectMask(mask.id)}
      />
      {showButtons && (
        <div
          className={`cursor-pointer ml-3 transition-opacity duration-300 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        >
          <AiOutlineRotateRight
            onClick={() => {
              handleRotateimage(mask.id);
            }}
          />
        </div>
      )}
    </motion.div>
  );
};
interface SelectionProps {
  masks: Mask[] | null;
  searchResultMasks: Mask[] | null;
  removeMask: (maskId: string) => void;
  rotateMaskImage: (maskId: string, flipMask: boolean) => void;
  toggleAnnotating: () => void;
  selectMask: (maskId: string) => void;
  filterMasks: (searchText: string) => void;
}

const Selection: FC<SelectionProps> = ({
  masks,
  searchResultMasks,
  removeMask,
  rotateMaskImage,
  toggleAnnotating,
  selectMask,
  filterMasks,
}) => {
  const [showSpines, setShowSpines] = useState(true);

  const handleToggle = () => {
    setShowSpines(!showSpines);
  };

  const handleTextChange = (text: string) => {
    filterMasks(text);
  };

  const isAnnotationEnabled =
    (masks && masks.length >= NUMBER_OF_BOOKS_TO_SELECT) || false;

  const masksToShow = searchResultMasks ? searchResultMasks : masks;
  return (
    <>
      <div className="flex flex-col mt-10 w-[57vw]">
        <div className="text-center text-xl font-bold">My Books</div>
        <div className="text-center">
          {(masks && masks.length) || 0} / {NUMBER_OF_BOOKS_TO_SELECT}
        </div>
        <div className="text-center">
          Click through your uploaded image to select{" "}
          {NUMBER_OF_BOOKS_TO_SELECT} books to highlight from your shelf.
        </div>
      </div>
      {false && (
        <div className="flex items-center justify-center w-[57vw]">
          <div className="mr-10 m-1 border-black">
            <SearchInput onTextChange={handleTextChange} />
          </div>
          <div className="flex items-center justify-center ml-10">
            <div className="text-center text-xl font-semibold mr-2">Covers</div>
            <Switch
              size="md"
              colorScheme="custom"
              _checked={{
                borderRadius: "10px",
                backgroundColor: "black",
                borderColor: "black",
                color: "white",
              }}
              onChange={() => handleToggle()}
            />
          </div>
        </div>
      )}
      {showSpines ? (
        <>
          <div
            className={`flex flex-col items-center h-[60vh] justify-center `}
          >
            <div className="rounded-lg my-3 h-auto flex-1 flex-col overflow-y-scroll items-center">
              <motion.div>
                <AnimatePresence initial={false}>
                  {masksToShow?.map((mask, index) => {
                    return (
                      <ImageWithButton
                        key={mask.id}
                        mask={mask}
                        removeMask={removeMask}
                        rotateMaskImage={rotateMaskImage}
                        selectMask={selectMask}
                        showButtons
                      />
                    );
                  })}
                </AnimatePresence>
              </motion.div>
            </div>
          </div>
          <button
            onClick={toggleAnnotating}
            disabled={!isAnnotationEnabled}
            className={`${
              isAnnotationEnabled
                ? "cursor-pointer opacity-100"
                : "cursor-not-allowed opacity-30"
            } w-[30vw] p-2.5 my-4 border border-black bg-black text-white rounded-md text-center font-medium`}
          >
            Annotate Books
          </button>
        </>
      ) : (
        <div className={`flex flex-col items-center h-[80vh] justify-center `}>
          <div className="flex flex-wrap w-[57vw] mt-6 flex-1 flex-col overflow-y-scroll items-center">
            {masksToShow?.map((mask, index) => {
              return <BookDisplay mask={mask} selectMask={selectMask} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Selection;
