import React, { useEffect, useState } from "react";
import { useSession } from "../../context/hooks/useSession";
import { SupabaseBook } from "../../api/books";
import { useMasks, Mask } from "../../hooks/useMasks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useImageData } from "../../hooks/useImage";
import {
  Spinner,
  useDisclosure,
  useEditable,
  useToast,
} from "@chakra-ui/react";

import { useBooks } from "../../hooks/useBooks";
import { S3_BUCKET_PREFIX } from "../../constants";
import { AiOutlineDownload } from "react-icons/ai";
import { generateCSV } from "../../utils/bookDownload";
import { BookCard, BookCardConfirm, EmptyBookCard } from "../Annotation";
import { SupabaseMask } from "../../api/masks";
import cx from "classnames";
import SearchInput from "../SearchInput";
import { BookLoadingComponent } from "./Loading";
import { BookErrorComponent } from "./Error";
import { BookGridItem } from "./GridItem";
import TabView from "./TabView";
import { AnnotationWrapper } from "./Annotation";

export enum Tab {
  VIEW = "View",
  ANNOTATE = "Annotate",
  FULL_PAGE = "View Full Page",
}

interface IGridAnnotationChild {
  imageId: string;
}

const ViewbooksChild: React.FC<IGridAnnotationChild> = ({ imageId }) => {
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);
  const [unprocessedMasks, setUnprocessedMasks] = useState<Mask[] | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const { session } = useSession();
  const userId = session?.user.id || "";
  const {
    masks,
    removeMask,
    persistMask,
    filterMasks,
    rotateMaskImage,
    searchResultMasks,
    reloadMasks,
  } = useMasks(imageId || null);
  const { image } = useImageData(imageId || "");
  const { books, addBookForMaskId } = useBooks(imageId || "");

  useEffect(() => {
    const unprocessedMasks = masks?.filter((mask) => {
      const foundBook = books?.find((book) => book.mask_id === mask.id);
      if (!foundBook) {
        return true;
      } else if (!foundBook.title || !foundBook.author) {
        return true;
      } else {
        return false;
      }
    });
    setUnprocessedMasks(unprocessedMasks || null);
  }, [masks, books]);

  const location = useLocation();
  const [fromVerifyStep, setFromVerifyStep] = useState(false);

  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.VIEW);

  useEffect(() => {
    // The URLSearchParams interface defines utility methods to work with the query string of a URL
    const params = new URLSearchParams(location.search);
    const fromVerifyStepParam = params.get("from-verify-step") === "true";
    setFromVerifyStep(fromVerifyStepParam);
    if (fromVerifyStep) {
      setSelectedTab(Tab.ANNOTATE);
    }
  }, [location]); // re-run the effect when the location changes

  useEffect(() => {
    if (books?.length === 0) {
      setSelectedTab(Tab.ANNOTATE);
    }
  }, [books]);

  const selectUnprocessedMask = () => {
    if (unprocessedMasks && unprocessedMasks?.length > 0) {
      console.log(unprocessedMasks);
      setSelectedMask(unprocessedMasks[0]);
    } else {
      toast({
        title: "Shelf Complete",
        description: "You have finished annotating this shelf",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setSelectedMask(null);
    }
  };

  const selectMask = (mask: Mask) => {
    setSelectedTab(Tab.ANNOTATE);
    setSelectedMask(mask);
  };

  const handleTextChange = (text: string) => {
    filterMasks(text);
  };
  const masksToShow = searchResultMasks ? searchResultMasks : masks;

  const backgroundImageUrl = `${S3_BUCKET_PREFIX}/${image?.url}`;

  useEffect(() => {
    if (selectedTab === Tab.FULL_PAGE) {
      navigate(`/full-page-selection/${imageId}`);
    }
  }, [selectedTab]);

  if (image == null || !image || !image.is_finished_annotating) {
    return <BookLoadingComponent backgroundImageUrl={backgroundImageUrl} />;
  }

  if (image.is_error_annotating || image.is_error_embedding) {
    return <BookErrorComponent />;
  }

  return (
    <div className="w-[100vw] h-[90vh] overflow-y-auto bg-[#F3F4F6]">
      <div className="z-10 fixed left-0 translate-x-[3%] translate-y-[10%] w-[35%] h-[80%] bg-white rounded-lg shadow">
        <div className="flex flex-col items-center h-[80vh] border rounded shadow bg-white ">
          <div className="w-full flex justify-between items-center p-2">
            <div className="font-bold text-lg font-lora">Books</div>
            <div>
              <div className="text-sm">
                {unprocessedMasks && unprocessedMasks.length} Books Left to
                Process
              </div>
            </div>
          </div>
          <div className="h-[70vh] overflow-scroll">
            {masks &&
              masks.map((mask, index) => {
                const maskImage = mask.maskImage
                  ? URL.createObjectURL(mask.maskImage)
                  : `${S3_BUCKET_PREFIX}/${
                      mask.crop_image_url ? mask.crop_image_url : mask.image_url
                    }` || "";
                return (
                  <div className="flex">
                    <img
                      onClick={() => {
                        selectMask(mask);
                      }}
                      key={index}
                      draggable="true"
                      src={maskImage}
                      alt="Book"
                      className="px-2 w-[30vw] max-h-[10vw] object-contain rounded-lg mt-4 cursor-pointer"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="z-10 fixed right-0 transform translate-x-[-3%] p-2 translate-y-[10%] w-[60%] h-[80%] bg-white shadow rounded-lg">
        <TabView
          currentTab={selectedTab}
          masks={masks}
          books={books}
          setTab={setSelectedTab}
        />
        {selectedTab === Tab.VIEW && (
          <div className="h-[88%] overflow-y-scroll grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 align-start auto-rows-min">
            {masksToShow &&
              masksToShow.map((mask) => (
                <BookGridItem
                  key={`list-${mask.id}`}
                  setSelectedMask={() => selectMask(mask)}
                  mask={mask}
                  books={books || []}
                  selectBook={addBookForMaskId}
                />
              ))}
          </div>
        )}
        {selectedTab === Tab.ANNOTATE && (
          <AnnotationWrapper
            selectedMask={selectedMask}
            nextMask={() => selectUnprocessedMask()}
            image={image}
            fromVerifyStep={fromVerifyStep}
            books={books}
            selectBook={addBookForMaskId}
            removeMask={removeMask}
            selectUnprocessedMask={selectUnprocessedMask}
          />
        )}
      </div>
      <div className="relative w-full h-[35vh] flex items-center justify-center">
        <img
          src={backgroundImageUrl}
          alt="photo of current bookshelf"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

function ViewBooks() {
  const { imageId } = useParams();
  return <ViewbooksChild imageId={imageId || ""} key={imageId} />;
}

export default ViewBooks;
