import React, { useEffect, useState, FC, useRef, useCallback } from "react";
import { useSession } from "../../context/hooks/useSession";
import { SupabaseBook, fetchBooksByImageId } from "../../api/books";
import {
  addBookToStory,
  fetchBooksByStoryId,
  fetchImageUrlFromBookSelection,
  fetchImageUrlandImageIdFromBookSelection,
  getStoryForUserId,
} from "../../api/stories";
import AnnotateMask, { BookCard, EmptyBookCard } from "../Annotation";
import { NUMBER_OF_BOOKS_TO_SELECT, S3_BUCKET_PREFIX } from "../../constants";
import {
  SupabaseQuestionAndAnswer,
  getQandAsForStoryId,
} from "../../api/questions";
import { motion, useMotionValue } from "framer-motion";
import { FaUserCircle, FaHome, FaSearch } from "react-icons/fa";

import PolygonMaskedImage from "../PolygonMaskedImage";
import ViewMaskedImage, { EditMaskedImage } from "../ViewMaskedImage";
import { useMasks, Mask } from "../../hooks/useMasks";
import Sidebar, { MaskState } from "../helpers/SideBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useProcessedImageData } from "../../hooks/useImage";
import { useModel } from "../../hooks/useModel";
import { LoadingBooksSVG } from "../ProcessShelf";
import { CloseButton, Spinner, position } from "@chakra-ui/react";
import FullPageSidebar, { FullPageBottomSidebar } from "./FullPageSidebar";

// Define possible positions
export enum Position {
  CENTER = "center",
  CENTER_LEFT = "center-left",
  CENTER_RIGHT = "center-right",
}

type SidebarMaskData = {
  maskId: string;
  state: MaskState;
};

interface IFullPageAnnotateChild {
  imageId: string;
}

const FullPageSelectionChild: React.FC<IFullPageAnnotateChild> = ({
  imageId,
}) => {
  const [hoveredId, setHoveredId] = useState<number | null>(0);
  const [sideBarData, setSideBarData] = useState<SidebarMaskData[]>([]);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [bookInfoPosition, setBookInfoPosition] = useState(Position.CENTER);
  const constraintsRef = useRef(null);
  const hasRun = useRef("");

  const setPosition = (position: Position) => {
    setBookInfoPosition(position);
  };

  const getDragConstraints = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const elementWidth = vw * 0.4; // 40vw
    const elementHeight = vh * 0.9; // 90vh
    let left = 0;
    let top = 0;
    let rightBuffer = 40;
    let leftBuffer = 40;
    let topBuffer = 30;
    switch (bookInfoPosition) {
      case Position.CENTER:
        left = (vw - elementWidth) / 2;
        top = (vh - elementHeight) / 2;
        break;
      case Position.CENTER_LEFT:
        left = 0 + leftBuffer;
        top = (vh - elementHeight) / 2 - topBuffer;
        break;
      case Position.CENTER_RIGHT:
        left = vw - elementWidth - rightBuffer;
        top = (vh - elementHeight) / 2 - topBuffer;
        break;
    }
    return { left, top };
  };
  const { left, top } = getDragConstraints();

  const navigate = useNavigate();
  const { session } = useSession();
  const userId = session?.user.id || "";
  const {
    masks,
    removeMask,
    persistMask,
    filterMasks,
    rotateMaskImage,
    searchResultMasks,
    reloadMasks,
  } = useMasks(imageId || null);
  const {
    image,
    loading: imageLoading,
    processingError: imageError,
  } = useProcessedImageData(imageId || "", userId, 1000, 60000, 6);
  const { isModelLoaded, sendClickToModel } = useModel({
    w: width,
    h: height,
    imageEmbeddingUrl: image?.embedding_url || null,
    persistMask,
  });

  const navigateToProcessingPage = () => {
    if (isJustViewing) {
      navigate(`/view-books/${image?.id}`);
    } else {
      navigate(`/view-books/${image?.id}?from-verify-step=true`);
    }
  };
  const location = useLocation();
  const isJustViewing = location.state?.justViewing;

  const [books, setBooks] = useState<SupabaseBook[] | null>(null);
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);
  const [showStartModal, setShowStartModal] = useState(true);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [showFinalModal, setShowFinalModal] = useState(false);

  const [isHorizontal, setIsHorizontal] = useState(true);
  const toggleSidebarLayout = () => setIsHorizontal((prev) => !prev);

  const scrollToMask = (maskId: string) => {
    const section = document.querySelector(`#mask-${maskId}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (masks) {
      const selectedMask = masks.filter((mask) => mask.id === maskId);
      if (selectedMask.length === 1) {
        setSelectedMask(selectedMask[0]);
        setShowSelectionModal(true);
      }
    }
  };

  useEffect(() => {
    if (bookInfoPosition === Position.CENTER_LEFT) {
      setIsHorizontal(true);
    } else {
      setIsHorizontal(false);
    }
  }, [bookInfoPosition]);

  useEffect(() => {
    async function getBooks() {
      if (imageId) {
        const data = (await fetchBooksByImageId(imageId)) as SupabaseBook[];
        setBooks(data);
      }
    }
    getBooks();
  }, [imageId]);

  const addConfirmedMask = (maskId: string) => {
    setSideBarData((prev) => {
      if (prev) {
        return prev
          .map((val) => {
            if (val.maskId === maskId) {
              return { maskId, state: MaskState.CONFIRMED };
            } else {
              return val;
            }
          })
          .sort((a, b) => {
            const statesOrder = { confirmed: 0, selected: 1, unselected: 2 };
            return statesOrder[a.state] - statesOrder[b.state];
          });
      } else {
        return [];
      }
    });
  };

  const addSelectedMask = (maskId: string) => {
    setSideBarData((prev) => {
      if (prev) {
        console.log(prev);
        const test = prev.concat({ maskId: maskId, state: MaskState.SELECTED });
        const toReturn = test.sort((a, b) => {
          const statesOrder = { confirmed: 0, selected: 1, unselected: 2 };
          return statesOrder[a.state] - statesOrder[b.state];
        });
        console.log(toReturn);
        return toReturn;
      } else {
        return [];
      }
    });

    setTimeout(() => {
      scrollToMask(maskId);
    }, 500);
  };

  useEffect(() => {
    if (hasRun.current && imageId) {
      hasRun.current = imageId;
    }
  }, [imageId]);

  useEffect(() => {
    if (!hasRun.current || hasRun.current != imageId) {
      if (masks && imageId && books) {
        let newMasksStatus = (
          masks?.map((mask) => {
            const book = books?.filter((book) => book.mask_id === mask.id)[0];
            if (book && (book.title || book.author)) {
              return { maskId: mask.id, state: MaskState.CONFIRMED };
            } else {
              return { maskId: mask.id, state: MaskState.SELECTED };
            }
          }) || []
        ).sort((a, b) => {
          const statesOrder = { confirmed: 0, selected: 1, unselected: 2 };
          return statesOrder[a.state] - statesOrder[b.state];
        });
        const difference = NUMBER_OF_BOOKS_TO_SELECT - newMasksStatus.length;
        if (difference > 0) {
          const toAppend = Array.from({ length: difference }).map((_) => {
            return { maskId: "", state: MaskState.UNSELECTED };
          });
          newMasksStatus = newMasksStatus.concat(toAppend);
        }
        console.log(newMasksStatus.length === NUMBER_OF_BOOKS_TO_SELECT);

        setSideBarData(newMasksStatus);
        hasRun.current = imageId;
      }
    }
  }, [masks, imageId, books]);

  /*
	 Ok. So we have a list of
	 [
		{maskId, selected | confirmed}
	 ]
	 generated on initial load. the rest are implied to be
	 [
	 {}
	 ] 
	 We want this to be sorted, such that confirmed are
	 above the selected ones
	*/
  if (image == null || !image) {
    return <div></div>;
  }

  if (!image.is_processed) {
    return (
      <div className="h-[80vh] flex flex-col items-center justify-center">
        <div className="relative">
          <Spinner className="absolute top-[14px]" />
        </div>
        <LoadingBooksSVG />
      </div>
    );
  }
  return (
    <div className="w-[100vw] h-auto overflow-y-auto" ref={constraintsRef}>
      <FullPageBottomSidebar
        masksStatus={sideBarData}
        isHorizontal={isHorizontal}
        toggleLayout={toggleSidebarLayout}
        hoveredId={hoveredId}
        setHoveredId={setHoveredId}
        selectMask={scrollToMask}
        navigateToProcessStep={navigateToProcessingPage}
        justViewing={isJustViewing}
      />
      <div className="absolute">
        <EditMaskedImage
          src={`${S3_BUCKET_PREFIX}/${image.url || ""}`}
          alt="test"
          polygons={masks}
          selectedMask={selectedMask}
          onMaskClick={scrollToMask}
          setPosition={setPosition}
          removeMask={removeMask}
          sendClickToModel={sendClickToModel}
          setNaturalImageHeight={setHeight}
          setNaturalImageWidth={setWidth}
          onCreateMask={addSelectedMask}
        />
      </div>
      {showStartModal && !showSelectionModal && (
        <motion.div
          initial={{ x: left, y: top }} // start in the center
          animate={{
            x: left,
            y: top,
          }}
          transition={{ type: "spring", stiffness: 30, damping: 10 }}
          className={`fixed z-10 flex flex-col items-center justify-center m-2 p-8 w-[40vw] max-h-[90vh] bg-white rounded-2xl`}
        >
          <CloseButton
            className="absolute top-3 right-3"
            onClick={() => setShowStartModal(false)}
          />

          <div className="flex flex-col items-center justify-center">
            <div className="text-center text-xl font-bold">My Shelf</div>
            {masks && masks.length > 0 ? (
              <div className="text-center">
                We detected {masks.length} books in this photo. We probably
                missed some! Please click on all of the books we missed, and
                when you are done, hit the next button on the sidebar!
              </div>
            ) : (
              <>
                <div className="text-center">
                  {(masks && masks.length) || 0} / {NUMBER_OF_BOOKS_TO_SELECT}
                </div>

                <div className="text-center">
                  To get started, close this and click on a book.
                </div>
              </>
            )}
            <div
              onClick={() => setShowStartModal(false)}
              className="cursor-pointer w-[14vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
            >
              Ok!
            </div>
          </div>
        </motion.div>
      )}
      {showSelectionModal && selectedMask && (
        <>
          <motion.div
            initial={{ x: left, y: top }} // start in the center
            animate={{
              x: left,
              y: top,
            }}
            transition={{ type: "spring", stiffness: 30, damping: 10 }}
            className={`fixed z-10 flex flex-col items-center justify-center m-2 p-8 w-[40vw] max-h-[90vh] bg-white rounded-2xl`}
          >
            <CloseButton
              className="absolute top-1 right-1"
              onClick={() => setShowSelectionModal(false)}
            />

            <div className="w-[40vw]">
              <div className="flex items-center justify-center">
                <img
                  src={
                    selectedMask.maskImage
                      ? URL.createObjectURL(selectedMask.maskImage)
                      : `${S3_BUCKET_PREFIX}/${selectedMask.image_url}` || ""
                  }
                  alt="Book spine"
                  className="w-[35vw] max-h-[10vw] object-contain rounded-lg mt-4"
                />
              </div>
            </div>
          </motion.div>
        </>
      )}
      {showFinalModal && <div></div>}
    </div>
  );
};

function FullPageSelection() {
  const { imageId } = useParams();
  return <FullPageSelectionChild imageId={imageId || ""} key={imageId} />;
}

export default FullPageSelection;
