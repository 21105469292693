import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import { useMasks } from "../../hooks/useMasks";
import { Mask } from "../../hooks/useMasks";
import { BooksWithMasks } from "./MaskDisplay";
import { S3_BUCKET_PREFIX } from "../../constants";
import { useImageData } from "../../hooks/useImage";
import { useBooks } from "../../hooks/useBooks";
import ProfilePhoto from "./basics/ProfilePhoto";
import { FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { BookDisplay } from "./BookDisplay";
import { useComments } from "../../hooks/useComments";
import { AiOutlineHeart } from "react-icons/ai";
import { BsReply, BsThreeDots, BsChatRightText } from "react-icons/bs";
import { IoMdArrowBack } from "react-icons/io";
import { FiShare2 } from "react-icons/fi";

const randomProfilePics = [
  "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/366.jpg",
  "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/189.jpg",
  "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/570.jpg",
  "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/515.jpg",
  "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1182.jpg",
  "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/690.jpg",
];

export const Discussion: React.FC = () => {
  // const imageId = "667d3098-2366-48a7-bd39-5844dc786efd";
  // const imageId = "98bc8b43-e7e9-45ed-8025-09c3e00319c6";
  const imageId = "065568e0-9c03-4cd8-a2b2-d9b6364ce8eb";
  const {
    masks,
    removeMask,
    persistMask,
    filterMasks,
    rotateMaskImage,
    searchResultMasks,
  } = useMasks(imageId || null);
  const [masksWithBook, setMasksWithBook] = useState<Mask[] | null>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { image } = useImageData(imageId || "");
  const { books, addBookForMaskId } = useBooks(imageId || "");
  const [selectedMask, setSelectedMask] = useState<Mask | null>(null);

  const [showLabels, setShowLabels] = useState<boolean>(true);

  const [bookIdFilter, setBookIdFilter] = useState<string | null>(null);

  const { comments, isLoading, createComment, deleteComment } = useComments(
    imageId,
    bookIdFilter
  );
  const [selectedComment, setSelectedComment] = useState(""); // track the selected comment

  // This function will update your state when the switch is toggled
  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setShowLabels(event.target.checked);
  };

  useEffect(() => {
    const masksWithBooks = masks?.filter((mask) => {
      const foundBook = books?.find((book) => book.mask_id === mask.id);
      if (!foundBook) {
        return false;
      } else if (!foundBook.title || !foundBook.author) {
        return false;
      } else {
        return true;
      }
    });
    setMasksWithBook(masksWithBooks || null);
  }, [masks, books]);

  const [commentDraft, setCommentDraft] = useState("");

  const addComment = () => {
    let commentBookId = null;
    if (selectedMask) {
      const foundBook = books?.find((book) => book.mask_id === selectedMask.id);
      if (foundBook) {
        commentBookId = foundBook.id;
      }
    }
    createComment("123", commentDraft, commentBookId);
  };

  useEffect(() => {
    if (selectedMask) {
      const foundBook = books?.find((book) => book.mask_id === selectedMask.id);
      if (foundBook) {
        setBookIdFilter(foundBook.id);
      }
    } else {
      setBookIdFilter(null);
    }
  }, [selectedMask]);

  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && commentDraft.trim()) {
      addComment();
      setCommentDraft("");
    }
  };
  const handleClick = () => {
    if (commentDraft.trim()) {
      addComment();
      setCommentDraft("");
    }
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-gray-100">
      <div className="flex ">
        <div className="max-w-[800px]">
          <div className="h-[50px] bg-white w-full border-b flex flex-col items-start justify-center p-3 border-t border-l ">
            <div className="w-full flex items-center justify-between">
              <div className="flex w-48 items-center ">
                <ProfilePhoto
                  imageUrl={
                    "https://pbs.twimg.com/profile_images/1506132892916744197/BGgZxSL3_400x400.jpg"
                  }
                />
                <div className="ml-2 font-bold">Luis Panini</div>
              </div>

              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <FormLabel htmlFor="email-alerts" mb="0">
                  Show Status
                </FormLabel>
                <Switch
                  id="show-labels-switch"
                  isChecked={showLabels}
                  onChange={handleToggle}
                />
              </FormControl>
            </div>
          </div>
          {image && (
            <BooksWithMasks
              key={imageId}
              src={`${S3_BUCKET_PREFIX}/${image.url}?test=ignore`}
              alt="test"
              polygons={masksWithBook}
              selectedMask={selectedMask}
              searchResultMasks={searchResultMasks}
              setSelectedMask={setSelectedMask}
              setImageDisplayHeight={setHeight}
              setImageDisplayWidth={setWidth}
              books={books}
              showLabels={showLabels}
            />
          )}
        </div>
        <div className="w-1/2 bg-white flex h-[calc(100vh-20px)] border flex-col justify-between w-[600px] w-[600px] rounded-r-lg rounded-b-lg ">
          <div className="w-full border-b flex flex-col items-start justify-center ">
            <div className="w-full h-[50px] flex items-center justify-between box-border border-b px-3">
              {selectedMask ? (
                <button
                  className="hover:bg-gray-100 rounded p-1"
                  onClick={() => setSelectedMask(null)}
                >
                  <div className="flex items-center ">
                    <IoMdArrowBack
                      size={22}
                      className="fill-gray-500 hover:fill-gray-800 mr-2 pr-1"
                    />
                    Back to Shelf
                  </div>
                </button>
              ) : (
                <div className="flex items-center font-bold ">
                  About the Shelf
                </div>
              )}
              <div className="flex ">
                <div className="text-gray-500">July 27, 2023</div>
              </div>
            </div>
            {!selectedMask && (
              <>
                <div className="px-6 py-4 overflow-y-scroll">
                  “Unreadable,” “too challenging” & “no, thank you.” These
                  (mostly) big boys have heard it all. Sometimes their scope is
                  intimidating, in some cases is the length what scares readers
                  away & often it’s the level of experimentation. Give them a
                  go. There’s a chance you may like them.
                </div>
              </>
            )}
            {selectedMask && books && (
              <BookDisplay
                key={selectedMask.id}
                mask={selectedMask}
                books={books}
              />
            )}
          </div>
          <div className="flex w-full flex-grow flex-col overflow-scroll ">
            {comments.map((comment) => {
              const commentsBook = books?.find(
                (book) => book.id === comment.bookId
              );
              const bookTitle = commentsBook?.title || null;
              const commentsMask = masks?.find(
                (mask) => mask.id == (commentsBook?.mask_id || "")
              );

              return (
                <div className="flex flex-col px-5 pt-3 pb-1 bg-white">
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      <div className="w-[30px] h-[30px]">
                        <ProfilePhoto
                          imageUrl={
                            "https://bookstack-store.s3.amazonaws.com/bookshelves/profiles/borges.png"
                          }
                        />
                      </div>
                      <a
                        target="_blank"
                        href="http://www.coldbacon.com/writing/borges-tlon.html"
                      >
                        <div className="ml-2 font-semibold hover:underline ">
                          Jorge Luis Borges
                        </div>
                      </a>
                      <div className="text-sm text-gray-500 ml-3">
                        August 1, 2023
                      </div>
                    </div>
                    <AiOutlineHeart
                      size={20}
                      className="fill-gray-300 cursor-pointer hover:fill-gray-600"
                    />
                  </div>
                  {bookTitle && !selectedMask && commentsMask && (
                    <div
                      onClick={() => setSelectedMask(commentsMask)}
                      className="ml-10 hover:bg-gray-300 text-xs cursor-pointer rounded bg-gray-100 p-1 w-max"
                    >
                      On <span className="italic">{bookTitle}</span>
                    </div>
                  )}
                  <div className="ml-10 my-1">{comment.content}</div>
                  <div className="ml-10">
                    <div className="flex items-center h-5">
                      <BsReply className="fill-gray-500 cursor-pointer hover:fill-gray-800" />
                      <BsThreeDots
                        className="ml-1 mt-0.5 fill-gray-500 cursor-pointer hover:fill-gray-800"
                        onClick={() => {
                          if (selectedComment === comment.id) {
                            setSelectedComment("");
                          } else {
                            setSelectedComment(comment.id);
                          }
                        }}
                      />
                      {selectedComment === comment.id && (
                        <button
                          className=" ml-2 text-xs text-red-800 border rounded px-1 hover:bg-red-100 "
                          onClick={() => deleteComment(comment.id)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {comments.length === 0 && (
              <div className="flex flex-col h-full items-center justify-center">
                <BsChatRightText size={30} className="fill-gray-600" />
                Start the Conversation
              </div>
            )}
          </div>
          <div className="h-[60px] flex items-center justify-between rounded-b-lg p-3 border-t">
            <div className="flex flex-grow items-center justify-center ">
              <div className="w-[30px] h-[30px]">
                <ProfilePhoto
                  imageUrl={
                    "https://bookstack-store.s3.amazonaws.com/bookshelves/profiles/borges.png"
                  }
                />
              </div>
              <input
                className="w-full mx-3 flex-grow rounded py-2 px-3 border-0 outline-none focus:outline-none placeholder-gray-400 focus:ring-2 focus:ring-gray-200"
                type="text"
                placeholder="Add a comment..."
                value={commentDraft}
                onChange={(e) => setCommentDraft(e.target.value)}
                onKeyDown={handleEnterPress}
              />
            </div>

            <button
              onClick={handleClick}
              type="submit"
              className={`${
                commentDraft ? "text-blue-600" : "text-blue-300"
              } cursor-pointer`}
              disabled={!commentDraft.trim()}
            >
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
