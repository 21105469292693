import * as React from "react";
import Login, { LogoutButton } from "./components/Login";
import {
  BrowserRouter as Router,
  Outlet,
  Routes,
  Route,
  NavLink,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useSession } from "./context/hooks/useSession";
import { Box, Flex, Link, Heading, Spacer } from "@chakra-ui/react";

import { Session } from "@supabase/supabase-js";
import Upload from "./components/Upload";
import ProcessShelf from "./components/ProcessShelf";

import { CgProfile } from "react-icons/cg";
import MultiShelf from "./components/MultiShelf";
import PickSelection from "./components/PickSelection";
import Picks from "./components/Picks";
import StackStory from "./components/StackStory";
import LandingPage from "./components/LandingPage";
import FullPageAnnotate from "./components/FullPageAnnotate";
import { ShelfRemix } from "./components/canvas/ShelfRemix";
import StackAnnotation from "./components/annotation/StackAnnotation";
import FullPageSelection from "./components/annotation/FullPageSelection";
import ViewBooks from "./components/view-books/ViewBooks";
import PageBrowser from "./components/chat/PageBrowser";
import { CreateWrapped } from "./components/wrapped/CreateWrapped";
import { Discussion } from "./components/discussion/Main";
import ViewWrapped from "./components/wrapped/ViewWrapped";
import { ViewAllWrapped } from "./components/wrapped/ViewAllWrapped";
interface ProtectedRouteProps {
  session: Session | null;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  session,
  children,
}) => {
  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

const Layout = () => {
  const location = useLocation();

  return (
    <>
      {!location.pathname.startsWith("/full-page-annotation") &&
        !location.pathname.startsWith("/story") &&
        !location.pathname.startsWith("/discuss") &&
        !location.pathname.startsWith("/wrapped") && (
          <div className="z-10 relative py-4 px-6 flex justify-center items-center border-b">
            <div className="font-bold font-lora text-3xl">
              <NavLink to="/login"> BookStack</NavLink>
            </div>
            <div className="absolute right-10 top-1/2 transform -translate-y-1/2 flex items-center space-x-4 text-sm">
              <NavLink to="/upload">
                <button className="border px-2 rounded py-1">
                  Upload Shelf
                </button>
              </NavLink>
              <NavLink to="/login">
                <button className="border px-2 rounded py-1">View Books</button>
              </NavLink>
              <LogoutButton />
            </div>
          </div>
        )}
      <Outlet />
    </>
  );
};

const App = () => {
  const { session } = useSession();
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<PageBrowser />} />
        <Route
          path="/full-page-annotation/:imageId"
          element={
            //<ProtectedRoute session={session}>
            <FullPageAnnotate />
            //</ProtectedRoute>
          }
        />
        <Route path="/annotation/:imageId" element={<StackAnnotation />} />
        <Route
          path="/full-page-selection/:imageId"
          element={<FullPageSelection />}
        />
        <Route path="/view-books/:imageId" element={<ViewBooks />} />

        <Route
          path="/annotation"
          element={
            // <ProtectedRoute session={session}>
            <ProcessShelf />
            // </ProtectedRoute>
          }
        />
        <Route path="/canvas/:imageId" element={<ShelfRemix />} />

        <Route path="/discuss" element={<Discussion />} />

        <Route
          path="/spines"
          element={
            // <ProtectedRoute session={session}>
            <MultiShelf />
            // </ProtectedRoute>
          }
        />
        <Route path="/wrapped/create" element={<CreateWrapped />} />
        <Route path="/wrapped/:wrappedId" element={<ViewWrapped />} />
        <Route path="/wrapped" element={<ViewAllWrapped />} />

        <Route
          path="/picks-selection"
          element={
            <PickSelection />
            // <ProtectedRoute session={session}>
            // </ProtectedRoute>
          }
        />
        <Route path="/story" element={<StackStory />} />
        <Route
          path="/upload"
          element={
            // <ProtectedRoute session={session}>
            <Upload />
            // </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};
export default App;
