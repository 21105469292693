// Annotation.js

import React, { useEffect, useState, FC } from "react";
import SegmentedImage from "./SegmentedImage";
import BookList from "./BookList";
import { useQuery } from "react-query";
import { useSession } from "../context/hooks/useSession";
import { fetchImages, ImageData } from "../api/images";
import { useNavigate, useParams } from "react-router-dom";
import { S3_BUCKET_PREFIX } from "../constants";
import { useMasks, Mask } from "../hooks/useMasks";
import { SearchBar } from "./SearchBar";
import PolygonMaskedImage, { Point } from "./PolygonMaskedImage";
import { useModel } from "../hooks/useModel";
import Selection, { ImageWithButton } from "./Selection";
import AnnotateMask, { BookCard, EmptyBookCard } from "./Annotation";
import {
  SupabaseBook,
  fetchBooksByImageId,
  fetchBooksByMaskId,
  fetchBooksByUserId,
} from "../api/books";
import { supabase } from "../supabase";

import { SupabaseMask } from "../api/masks";
import {
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import {
  addBookToStory,
  fetchBooksByStoryId,
  getStoryForUserId,
  removeBookFromStory,
} from "../api/stories";
import { useForm, Controller } from "react-hook-form";
import {
  addOrUpdateQuestionAndAnswer,
  getQandAsForStoryId,
} from "../api/questions";

export interface MaskWithImage extends SupabaseMask {
  image: any | null;
}

interface IStackQuestionsModal {
  isOpen: boolean;
  onClose: () => void;
  storyId: string;
}

const StackQuestionsModal: FC<IStackQuestionsModal> = ({
  isOpen,
  onClose,
  storyId,
}) => {
  const { handleSubmit, control, reset } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        let resetObject = questions.reduce(
          (obj: { [k: string]: any }, item, index) => {
            obj[`answer-${index}`] = item;
            return obj;
          },
          {}
        );
        // restObject = {answer-0: '', answer-1: '', answer-2: ''}

        const response = await getQandAsForStoryId(storyId);
        response?.map((qAndA) => {
          questions.map((q, index) => {
            if (q.questionText == qAndA.question) {
              resetObject[`answer-${index}`] = qAndA.answer;
            }
          });
        });
        reset(resetObject);
      } catch (error) {
        console.error("Error loading initial data: ", error);
      }
    };

    if (storyId) {
      fetchInitialData();
    }
  }, [reset, storyId]);

  const onSubmit = async (data: any) => {
    async function addQuestionsAndAnswers() {
      Object.entries(data).map(async ([key, value]) => {
        const idx = Number(key.split("-")[1]);
        const question = questions[idx].questionText;
        const answer = value as string;
        await addOrUpdateQuestionAndAnswer(question, answer, storyId);
      });
    }
    addQuestionsAndAnswers();
    onClose();
  };

  const questions = [
    {
      questionText: "What sort of things do you like to read?",
    },
    {
      questionText: "What was your favorite book of all time? ",
    },
    { questionText: "What are you currently reading?" },
  ];
  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center justify-center">
            <div className="font-bold text-2xl">
              Tell us more about your shelves!
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center justify-center w-[100%] mx-auto my-0"
          >
            {questions.map((question, index) => (
              <div className="flex flex-col w-full" key={index}>
                <div className="mt-2 mb-2 font-bold">
                  {question.questionText}
                </div>
                <Controller
                  name={`answer-${index}`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Textarea
                      rows={4}
                      size="lg"
                      className="w-full"
                      {...field}
                    />
                  )}
                />
              </div>
            ))}
            <button
              onClick={() => navigate("/story")}
              className="cursor-pointer w-[30vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
              type="submit"
            >
              Publish
            </button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface IBookPickModal {
  isOpen: boolean;
  onClose: () => void;
  selectBook: (book: SupabaseBook) => void;
}

const BookPickModal: FC<IBookPickModal> = ({ isOpen, onClose, selectBook }) => {
  const [books, setBooks] = useState<SupabaseBook[] | null>(null);
  const { session } = useSession();
  const userId = session?.user.id || "";
  useEffect(() => {
    async function getBooks() {
      const data = (await fetchBooksByUserId(userId)) as SupabaseBook[];
      setBooks(data);
    }
    getBooks();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Books to Display</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="h-[60vh]">
            <div className="flex flex-col h-full items-center overflow-auto ">
              {books &&
                books.map((book, index) => {
                  if (book.title || book.author) {
                    return (
                      <div className="w-[27vw]" key={`book-${index}`}>
                        <BookCard
                          title={book.title || ""}
                          cover={book.cover || ""}
                          author={book.author || ""}
                          onClick={() => {
                            selectBook(book);
                            onClose();
                          }}
                          key={`book-${index}`}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function PickSelection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isQuestionModalOpen,
    onOpen: openQuestionModal,
    onClose: closeQuestionModal,
  } = useDisclosure();
  const [storyId, setStoryId] = useState("");
  const [storyBooks, setStoryBooks] = useState<SupabaseBook[] | null>(null);
  const { session } = useSession();
  const userId = session?.user.id || "";

  useEffect(() => {
    async function getBooks() {
      const story = await getStoryForUserId(userId);
      if (story) {
        setStoryId(story.id);
      }
    }
    getBooks();
  }, []);

  useEffect(() => {
    async function getStoryBooks() {
      if (storyId) {
        const stories = await fetchBooksByStoryId(storyId);
        setStoryBooks(stories);
      }
    }
    getStoryBooks();
  }, [storyId]);

  const selectBook = (book: SupabaseBook) => {
    if (!storyId) {
      return;
    }
    async function addBook() {
      await addBookToStory(book.id, storyId);
    }
    addBook();

    setStoryBooks((prevBooks) => {
      if (prevBooks) {
        return [...prevBooks, book];
      } else {
        return [book];
      }
    });
  };

  const removeBook = (book: SupabaseBook) => {
    if (!storyId) {
      return;
    }
    async function removeBook() {
      await removeBookFromStory(book.id, storyId);
    }
    removeBook();
    setStoryBooks((prevBooks) => {
      if (prevBooks) {
        return prevBooks.filter((prevBook) => prevBook.id !== book.id);
      } else {
        // if no previous books exist, return an empty array or any other default value you wish.
        return [];
      }
    });
  };

  return (
    <div>
      <div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold text-xl">Your Picks</div>
          <div className="">
            Select at least four books from your shelves to highlight!
          </div>
        </div>
        <div className="mt-10 w-7/10 h-[60vh] rounded-lg p-8 mx-80 bg-gray-100 flex overflow-scroll flex-col items-center justify-center">
          <div className="flex flex-col h-full items-center w-[30vw]">
            {Array.from({ length: 1 }).map((_, index) => (
              <EmptyBookCard
                key="empty Card"
                onClick={() => {
                  onOpen();
                }}
              />
            ))}

            {storyBooks &&
              storyBooks.length > 0 &&
              storyBooks.map((book, index) => (
                <BookCard
                  title={book.title || ""}
                  author={book.author || ""}
                  cover={book.cover || ""}
                  key={`book-${index}`}
                  onClose={() => removeBook(book)}
                />
              ))}
          </div>
          <BookPickModal
            isOpen={isOpen}
            onClose={onClose}
            selectBook={selectBook}
          />
          <StackQuestionsModal
            isOpen={isQuestionModalOpen}
            onClose={closeQuestionModal}
            storyId={storyId}
          />
        </div>
        {storyBooks && storyBooks.length > 3 && (
          <div className="flex justify-center align-center">
            <div
              onClick={openQuestionModal}
              className="cursor-pointer w-[30vw] p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
            >
              Continue
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PickSelection;
