import React, { FC, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { S3_BUCKET_PREFIX } from "../constants";
import { fetchImages, ImageData } from "../api/images";
import { ShelfCard } from "./LandingPage";

type UserImagesProps = {
  userId: string;
};

type CardProps = {
  image: ImageData;
  handleClick: (val: string) => void;
};
const Card: React.FC<CardProps> = ({ image, handleClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      onClick={() => handleClick(image.id)}
      className="m-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 hover:shadow-lg cursor-pointer transition-shadow duration-200 bg-white rounded-md shadow-md relative group"
    >
      {image.url && (
        <img
          src={`${S3_BUCKET_PREFIX}/${image.url}`}
          alt="Image"
          className="w-full h-auto object-cover rounded-md"
        />
      )}

      <button
        className="absolute top-2 right-2 w-8 h-8 text-gray-500 hover:text-gray-700 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          setShowDropdown(!showDropdown);
        }}
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>

      {showDropdown && (
        <div className="absolute right-0 mt-2 w-48 py-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
          <button
            className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
            onClick={() => {
              console.log("Delete Image");
            }}
          >
            Delete Image
          </button>
        </div>
      )}
    </div>
  );
};

const UserImages: React.FC<UserImagesProps> = ({ userId }) => {
  const { isLoading, isError, error, data, refetch } = useQuery<
    ImageData[],
    Error
  >(["userImages", userId], () => fetchImages(userId), { enabled: !!userId });
  const navigate = useNavigate();

  const handleClick = (imageId: string) => {
    navigate(`/view-books/${imageId}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error!.message}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col justify-center items-center">
        <div className="font-bold text-xl font-lora">My Shelves</div>
        <div className="mt-3"></div>
        <div className="flex items-center justify-center w-[30vw]"></div>
      </div>
      <div className="flex items-center justify-center flex-wrap -mx-2">
        {data!.map((image, index) => (
          <ShelfCard
            onClick={() => handleClick(image.id)}
            imageUrl={`${S3_BUCKET_PREFIX}/${image.url}`}
            name={`${image.title || `Shelf ${index + 1}`}`}
            onClose={() => {}}
          />
          // <Card key={image.id} image={image} handleClick={handleClick} />
        ))}
      </div>
    </div>
  );
};

export default UserImages;
