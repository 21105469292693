import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImageData, fetchImage } from "../api/images";

export const useImageData = (imageId: string) => {
  const [image, setImage] = useState<ImageData | null>(null);
  useEffect(() => {
    async function fetchData() {
      const data = await fetchImage(imageId);
      setImage(data);
    }
    fetchData();
  }, [imageId]);
  return { image };
};

export const useProcessedImageData = (
  imageId: string | null,
  userId: string | null,
  initialInterval: number,
  maxInterval: number,
  maxAttempts: number
) => {
  const navigate = useNavigate();
  const [image, setImage] = useState<ImageData | null>(null);
  const [loading, setLoading] = useState(true);
  const [interval, setIntervalState] = useState(initialInterval);
  const [fetchCount, setFetchCount] = useState(0);
  const [processingError, setProcessingError] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    (async function fetchData() {
      if (imageId && userId) {
        setLoading(true);
        const data = await fetchImage(imageId);
        if (data.owner_id && data.owner_id !== userId) {
          navigate("/login");
        } else if (!data.is_processed) {
          console.log("re-process");
          // Image is not processed yet - double the interval, but do not exceed the maximum
          setIntervalState((prevInterval) =>
            Math.min(prevInterval * 2, maxInterval)
          );
          if (fetchCount < maxAttempts) {
            // If the image is not processed and we're under the max attempts, schedule another fetch
            timer = setTimeout(
              () => setFetchCount((prevCount) => prevCount + 1),
              interval
            );
          } else {
            // If we've exceeded the max attempts, set the error state
            setProcessingError(true);
          }
        } else {
          // Image is processed - reset the interval
          setIntervalState(initialInterval);
        }
        setImage(data);
        setLoading(false);
      }
    })();

    return () => {
      clearTimeout(timer);
    };
  }, [
    imageId,
    userId,
    initialInterval,
    maxInterval,
    maxAttempts,
    navigate,
    fetchCount,
  ]);

  useEffect(() => {
    if (interval !== initialInterval) {
      console.log(`Will re-process in ${interval / 1000} seconds`);
    }
  }, [interval, initialInterval]);

  return { image, loading, processingError };
};
