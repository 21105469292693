import React, { useEffect, useState, FC, useRef } from "react";

import { Mask } from "../../hooks/useMasks";
import { motion, useMotionValue } from "framer-motion";
import { FaUserCircle, FaHome, FaSearch } from "react-icons/fa";
import { GiBlackBook, GiWhiteBook } from "react-icons/gi";
import { NUMBER_OF_BOOKS_TO_SELECT } from "../../constants";
import { BsBookmark, BsFillArrowRightCircleFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { json } from "stream/consumers";

// Define possible positions
export enum MaskState {
  UNSELECTED = "unselected",
  CONFIRMED = "confirmed",
  SELECTED = "selected",
}
interface ListItem {
  id: number;
  title: string;
}

const items: ListItem[] = [
  { id: 1, title: "Item 1" },
  { id: 2, title: "Item 2" },
  { id: 3, title: "Item 3" },
  { id: 4, title: "Item 4" },
  { id: 5, title: "Item 3" },
  { id: 6, title: "Item 3" },
  { id: 7, title: "Item 3" },
];

function Sidebar({
  masksStatus,
  hoveredId,
  setHoveredId,
  toggleLayout,
  isHorizontal,
  selectMask,
}: {
  masksStatus: { maskId: string; state: string }[];
  hoveredId: number | null;
  setHoveredId: (id: number | null) => void;
  toggleLayout: () => void;
  isHorizontal: boolean;
  selectMask: (maskId: string) => void;
}) {
  const gaussianAggressiveness = 0.1;
  return (
    <motion.div
      className={`fixed transform z-20 ${
        isHorizontal
          ? "top-1/2 -translate-y-1/2 right-10 h-[65vh] w-20 flex flex-col items-center justify-center"
          : "top-1/2 -translate-y-1/2 left-10 h-[65vh] w-20 flex flex-col items-center justify-center"
      } bg-white shadow-sm rounded-2xl`}
      // onClick={toggleLayout}
    >
      <div className="p-4 flex justify-center cursor-pointer">
        <NavLink to="/login">
          <FaHome size={22} />
        </NavLink>
      </div>

      <div key={JSON.stringify(masksStatus)} className={`overflow-y-auto`}>
        {masksStatus.slice(0, NUMBER_OF_BOOKS_TO_SELECT).map((item, index) => {
          const offsetScale =
            hoveredId !== null
              ? Math.exp(
                  -gaussianAggressiveness * Math.pow(index - hoveredId, 2)
                )
              : 0;

          return (
            <div
              key={`sidebar-${item.maskId}`}
              className="p-2 relative w-full flex justify-center"
              onMouseEnter={() => setHoveredId(index)}
              onMouseLeave={() => setHoveredId(null)}
              onClick={() => {
                selectMask(item.maskId);
              }}
            ></div>
          );
        })}
      </div>

      <div className="p-4 flex flex-col items-center cursor-pointer">
        {" "}
        {masksStatus.reduce((accumulator, current) => {
          return current.state === MaskState.CONFIRMED
            ? accumulator + 1
            : accumulator;
        }, 0)}{" "}
        / {NUMBER_OF_BOOKS_TO_SELECT}
      </div>
      <div>
        <NavLink to="/picks-selection">
          <BsFillArrowRightCircleFill size={22} />
        </NavLink>
      </div>
    </motion.div>
  );
}

export default Sidebar;

export function BottomSidebar({
  masksStatus,
  hoveredId,
  setHoveredId,
  toggleLayout,
  isHorizontal,
  selectMask,
}: {
  masksStatus: { maskId: string; state: string }[];
  hoveredId: number | null;
  setHoveredId: (id: number | null) => void;
  toggleLayout: () => void;
  isHorizontal: boolean;
  selectMask: (maskId: string) => void;
}) {
  const gaussianAggressiveness = 0.1;
  return (
    <motion.div
      className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 z-20 ${
        isHorizontal
          ? "w-50vw h-20 flex flex-row items-center justify-center"
          : "w-[30vw] h-20 flex flex-row items-center justify-between"
      } bg-white shadow-sm rounded-2xl`}
    >
      <div className="p-4 flex justify-center cursor-pointer">
        <NavLink to="/login">
          <FaHome size={22} />
        </NavLink>
      </div>
      <div className="font-bold text-2xl">
        <NavLink to="/login">BookStack</NavLink>
      </div>

      <div className="p-4 flex justify-center cursor-pointer">
        <BsBookmark size={22} />
      </div>
    </motion.div>
  );
}
