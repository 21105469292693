import React, { useEffect, useState } from "react";
import { Mask } from "../../hooks/useMasks";
import { SupabaseBook } from "../../api/books";

import { BookSearchModal, GoogleBook } from "../annotation/BookSearch";
import { AnnotationCard } from "../annotation/StackAnnotation";
import { ImageData } from "../../api/images";
import { useNavigate } from "react-router-dom";

interface AnnotationCardProps {
  selectedMask: Mask | null;
  nextMask: () => void;
  image: ImageData;
  fromVerifyStep: boolean;
  books: SupabaseBook[] | null;
  selectBook: (book: GoogleBook, maskId: string) => void;
  removeMask: (maskId: string) => void;
  selectUnprocessedMask: () => void;
}

export const AnnotationWrapper: React.FC<AnnotationCardProps> = ({
  selectedMask,
  books = [],
  selectBook,
  removeMask,
  nextMask,
  image,
  fromVerifyStep,
  selectUnprocessedMask,
}) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[80%] ">
      <div className="flex items-center justify-center">
        {selectedMask ? (
          <AnnotationCard
            key={`list-${selectedMask.id}`}
            nextMask={nextMask}
            mask={selectedMask}
            books={books || []}
            selectBook={selectBook}
            removeMask={removeMask}
          />
        ) : (
          <div className="flex items-center flex-col justify-between w-full h-full bg-white">
            <div className="p-4">
              <div className="font-bold text-lg">Annotation Instructions</div>
              {!fromVerifyStep && (
                <>
                  <div>
                    We've proccessed your shelf, but let's make sure we didn't
                    miss any books. We'll now take you to your shelf, where you
                    will get the chance to click through books we didn't see.
                  </div>
                  <div className="w-full flex items-center justify-center">
                    <div className="flex items-center justify-between w-3/5 ">
                      <button
                        onClick={() =>
                          navigate(`/full-page-selection/${image?.id}`)
                        }
                        className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                      >
                        Verify Shelf
                      </button>
                      or
                      <button
                        onClick={() => {
                          selectUnprocessedMask();
                        }}
                        className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                      >
                        Start Annotating
                      </button>
                    </div>
                  </div>
                </>
              )}
              {fromVerifyStep && (
                <>
                  <div>
                    Thanks for confirming those books! Now, we will begin the
                    annotation process. At any point, you can see the results by
                    clicking the View Shelf button
                  </div>
                  <div className="w-full flex items-center justify-center">
                    <div className="flex items-center justify-center w-full ">
                      <button
                        onClick={() => {
                          selectUnprocessedMask();
                        }}
                        className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
                      >
                        Start Annotating
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
