import React from "react";

type ProfilePhotoProps = {
  imageUrl: string;
  size?: number; // optional size prop in pixels
};

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ imageUrl, size = 30 }) => {
  const style = {
    width: size,
    height: size,
    borderRadius: "50%",
    overflow: "hidden",
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  };

  return <div style={style} />;
};

export default ProfilePhoto;
