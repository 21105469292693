import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import { MentionList } from "./MentionList";
import { authors } from "../authors";

const getFilteredMentions = (query) => {
	return authors.map((val) => { return { id: val.id, label: val.name, type: "author" } })
		.filter((item) => item.label.toLowerCase().startsWith(query.toLowerCase()))
		.slice(0, 5);
};

export default {
	items: ({ query }) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(getFilteredMentions(query));
			}, 200);
		});
	},

	render: () => {
		let reactRenderer;
		let popup;

		return {
			onStart: (props) => {
				reactRenderer = new ReactRenderer(MentionList, {
					props,
					editor: props.editor
				});

				popup = tippy("body", {
					getReferenceClientRect: props.clientRect,
					appendTo: () => document.body,
					content: reactRenderer.element,
					showOnCreate: true,
					interactive: true,
					trigger: "manual",
					placement: "bottom-start"
				});
			},

			onUpdate(props) {
				reactRenderer.updateProps(props);

				popup[0].setProps({
					getReferenceClientRect: props.clientRect
				});
			},

			onKeyDown(props) {
				if (props.event.key === "Escape") {
					popup[0].hide();

					return true;
				}

				return reactRenderer?.ref?.onKeyDown(props);
			},

			onExit() {
				popup[0].destroy();
				reactRenderer.destroy();
			}
		};
	}
};
