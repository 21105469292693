import React, { forwardRef } from "react";
import Editor from "./editor/Editor";
import { PageData } from "./context/pageContext";

interface PageProps {
  pageData: PageData;
  pageIndex: number;
  activePageIndex: number;
  removePage: (index: number) => void;
  addPage: (title: string) => void;
  scrollToPage: () => void;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    { pageData, pageIndex, activePageIndex, removePage, addPage, scrollToPage },
    pageRef
  ) => {
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as Element;
      if (target.getAttribute("data-type") === "author") {
        const id = target.getAttribute("data-id");
        const label = target.getAttribute("data-label");
        addPage(label || "");
      }
    };
    return (
      <div
        key={pageData.title}
        ref={pageRef}
        style={{ left: (pageIndex + 1) * 40 }}
        className={`sticky h-[85vh] border p-4 m-4 shadow rounded bg-white`}
      >
        <div className="w-[45vw]">
          {pageIndex >= activePageIndex && (
            <>
              <h1
              // keep for now, helpful for focus debugging
              // style={{
              //   color: pageIndex <= activePageIndex ? "red" : "black",
              // }}
              >
                <b>{pageData.title}</b>
              </h1>
              <div>{pageIndex >= activePageIndex && pageData.content} </div>
              <Editor handleClick={handleClick} />
              {pageIndex !== 0 && (
                <button onClick={() => removePage(pageIndex)}>
                  Remove this page
                </button>
              )}
            </>
          )}
        </div>
        {pageIndex < activePageIndex && (
          <button
            onClick={scrollToPage}
            className="absolute top-1/4 left-5 transform -translate-y-3/4 -translate-x-1/2 rotate-90"
          >
            {pageData.title}
          </button>
        )}
      </div>
    );
  }
);

export default Page;
