import { supabase } from "../supabase";
import { Database } from "../../types/supabase";

export type SupabaseWrappedObject =
  Database["public"]["Tables"]["wrapped"]["Row"];

export type WriteWrappedObject = {
  header: string;
  subHeader: string;
  summary: string;
  books: any;
};

export const addWrapped = async (input: WriteWrappedObject) => {
  const payload = {
    header: input.header,
    ownerName: "",
    sub_header: input.subHeader,
    summary: input.summary,
    books: input.books,
  };
  const { data, error } = await supabase
    .from("wrapped")
    .insert([payload])
    .select();
  console.log(data, error);
  if (error) throw error;
  return data;
};

export const getWrappedById = async (wrappedId: string) => {
  const { data, error } = await supabase
    .from("wrapped")
    .select("*")
    .eq("id", wrappedId);
  if (error) throw error;
  return data[0];
};

export const getAllWrapped = async () => {
  const { data, error } = await supabase.from("wrapped").select("*");
  if (error) throw error;
  return data;
};

export const deleteWrapped = async (id: string) => {
  const { data, error } = await supabase.from("wrapped").delete().match({ id });
  if (error) throw error;
  return data;
};
