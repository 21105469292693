import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useWrapped } from "../../hooks/useWrapped";
import { SupabaseWrappedObject } from "../../api/wrapped";
import { ViewWrappedCard } from "./WrappedCard";

interface ViewWrappedChildProps {
  wrappedId: string;
}

const ViewWrappedChild: React.FC<ViewWrappedChildProps> = ({ wrappedId }) => {
  const { get } = useWrapped();

  const [wrappedObject, setWrappedObject] =
    useState<SupabaseWrappedObject | null>(null);

  useEffect(() => {
    async function fetchWrapped(wrappedId: string) {
      const data = await get(wrappedId);
      if (data) {
        setWrappedObject(data);
      }
    }

    if (wrappedId) {
      fetchWrapped(wrappedId);
    }
  }, [wrappedId]);

  return (
    <div className="flex flex-col h-screen w-screen bg-gray-100 items-center justify-center">
      {wrappedObject && (
        <ViewWrappedCard showViewAll={true} wrappedObject={wrappedObject} />
      )}{" "}
    </div>
  );
};

function ViewWrapped() {
  const { wrappedId } = useParams();
  return <ViewWrappedChild wrappedId={wrappedId || ""} />;
}

export default ViewWrapped;
