import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { Search } from "react-router-dom";
import { GoogleBook } from "../annotation/BookSearch";
import { BACKEND_URL } from "../../constants";
import { Spinner } from "@chakra-ui/react";

interface SearchProps {
  setResults: (items: GoogleBook[]) => void;
}

const Search: React.FC<SearchProps> = ({ setResults }) => {
  const [query, setQuery] = useState("");
  const searchRef = useRef(debounce((q) => search(q), 500)).current;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (query) {
      setIsLoading(true);
      searchRef(query);
    }
  }, [query]);

  const search = async (q: string) => {
    if (!q) {
      setResults([]);
    }
    try {
      const res = await axios.get<{ data: GoogleBook[] }>(
        `${BACKEND_URL}/search/?query=${q}`
      );
      if (res && res.data && res.data.data) {
        setResults(res.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="mb-2 flex ">
      <input
        autoFocus
        className="border-2 border-gray-300 p-2 w-full"
        type="text"
        placeholder="Search..."
        onChange={(e) => setQuery(e.target.value)}
      />
      {isLoading && (
        <div className="ml-2 flex items-center justify-center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
        </div>
      )}
    </div>
  );
};

export default Search;
