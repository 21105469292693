import React, { useEffect, useState, FC, useRef } from "react";

import { Mask } from "../../hooks/useMasks";
import { motion, useMotionValue } from "framer-motion";
import { FaUserCircle, FaHome, FaSearch } from "react-icons/fa";
import { GiBlackBook, GiWhiteBook } from "react-icons/gi";
import { NUMBER_OF_BOOKS_TO_SELECT } from "../../constants";
import { BsBookmark, BsFillArrowRightCircleFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { json } from "stream/consumers";

// Define possible positions
export enum MaskState {
  UNSELECTED = "unselected",
  CONFIRMED = "confirmed",
  SELECTED = "selected",
}
interface ListItem {
  id: number;
  title: string;
}

const items: ListItem[] = [
  { id: 1, title: "Item 1" },
  { id: 2, title: "Item 2" },
  { id: 3, title: "Item 3" },
  { id: 4, title: "Item 4" },
  { id: 5, title: "Item 3" },
  { id: 6, title: "Item 3" },
  { id: 7, title: "Item 3" },
];

function FullPageSidebar({
  masksStatus,
  hoveredId,
  setHoveredId,
  toggleLayout,
  isHorizontal,
  selectMask,
}: {
  masksStatus: { maskId: string; state: string }[];
  hoveredId: number | null;
  setHoveredId: (id: number | null) => void;
  toggleLayout: () => void;
  isHorizontal: boolean;
  selectMask: (maskId: string) => void;
}) {
  const gaussianAggressiveness = 0.1;
  return (
    <motion.div
      className={`fixed transform z-20 ${
        isHorizontal
          ? "top-1/2 -translate-y-1/2 right-10 h-[65vh] w-20 flex flex-col items-center justify-center"
          : "top-1/2 -translate-y-1/2 left-10 h-[65vh] w-20 flex flex-col items-center justify-center"
      } bg-white shadow-sm rounded-2xl`}
    >
      <div className="p-4 flex justify-center cursor-pointer">
        <NavLink to="/login">
          <FaHome size={22} />
        </NavLink>
      </div>

      <div>
        Completed Selecting books
        <NavLink to="/picks-selection">
          <BsFillArrowRightCircleFill size={22} />
        </NavLink>
      </div>
    </motion.div>
  );
}

export default FullPageSidebar;

export function FullPageBottomSidebar({
  masksStatus,
  hoveredId,
  setHoveredId,
  toggleLayout,
  isHorizontal,
  selectMask,
  navigateToProcessStep,
  justViewing,
}: {
  masksStatus: { maskId: string; state: string }[];
  hoveredId: number | null;
  setHoveredId: (id: number | null) => void;
  toggleLayout: () => void;
  isHorizontal: boolean;
  selectMask: (maskId: string) => void;
  navigateToProcessStep: () => void;
  justViewing: boolean;
}) {
  const gaussianAggressiveness = 0.1;
  return (
    <motion.div
      className={`fixed bottom-5 left-1/2 transform -translate-x-1/2 z-20 ${
        isHorizontal
          ? "w-[30vw] h-20 flex flex-row items-center justify-center"
          : "w-[30vw] h-20 flex items-center justify-center"
      } bg-white shadow-sm rounded-2xl`}
    >
      <div className="flex items-center justify-center">
        <button
          onClick={() => {
            navigateToProcessStep();
          }}
          className="z-10 p-2.5 my-4 border border-black hover:bg-black hover:text-white rounded-sm text-center font-medium"
        >
          {justViewing ? "Return to Books" : "Finished Selection"}
        </button>
      </div>
    </motion.div>
  );
}
