// Annotation.js

import React, { useEffect, useState } from "react";
import SegmentedImage from "./SegmentedImage";
import BookList from "./BookList";
import { useQuery } from "react-query";
import { useSession } from "../context/hooks/useSession";
import { fetchImages, ImageData } from "../api/images";
import { useParams } from "react-router-dom";
import { S3_BUCKET_PREFIX } from "../constants";
import { useMasks, Mask } from "../hooks/useMasks";
import { SearchBar } from "./SearchBar";
import PolygonMaskedImage, { Point } from "./PolygonMaskedImage";
import { useModel } from "../hooks/useModel";
import Selection, { ImageWithButton } from "./Selection";
import AnnotateMask from "./Annotation";
import { fetchBooksByImageId, fetchBooksByMaskId } from "../api/books";
import { supabase } from "../supabase";

import { SupabaseMask } from "../api/masks";
export interface MaskWithImage extends SupabaseMask {
  image: any | null;
}

function MultiShelf() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [masks, setMasks] = useState<Mask[] | null>(null);
  const { session } = useSession();
  const userId = session?.user.id || "";
  const { data: images } = useQuery<ImageData[], Error>(
    ["userImages", userId],
    () => fetchImages(userId),
    { enabled: !!userId }
  );

  useEffect(() => {
    if (!images) {
      return;
    }
    async function getMasks() {
      const { data, error } = await supabase
        .from("masks")
        .select("*, images!inner(owner_id)")
        .eq("images.owner_id", userId);

      if (error) {
        console.error("Error fetching masks from supabase:", error);
      }
      const loadedMasks = data?.map((maskWithImage) => {
        const { images, ...mask } = maskWithImage; //must be a better way
        return mask;
      }) as Mask[];
      if (loadedMasks) {
        setMasks(loadedMasks);
      }
    }
    getMasks();
  }, [images]);

  return (
    <div>
      <div className={`flex flex-col items-center h-[110vh] justify-center `}>
        <div className="rounded-lg h-[30vh] rotate-90 flex-1 flex-col overflow-y-scroll items-center">
          {masks?.map((mask, index) => {
            return (
              <ImageWithButton
                key={index}
                mask={mask}
                removeMask={(maskId: string) => {}}
                rotateMaskImage={(maskId: string) => {}}
                selectMask={(maskId: string) => {}}
                showButtons={false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MultiShelf;
